import React, {
  createContext,
  type ReactNode,
  useMemo,
  useReducer,
} from 'react';
import {
  type BankCode,
  type ConnectionDetail,
  type Goal,
  type Offer,
  type ProductType,
  productTypes,
} from '../services/pbl-api';
import { type PageValue } from './constants';
import { restrictedPageOrder } from './constants';

type Requester = {
  name?: string;
  phoneNumber?: string;
  email?: string;
  surname?: string;
  amount?: number;
  hasExpenses?: boolean;
  knowsAmount?: boolean;
  expenses?: number;
  company?: string;
  kvkNumber?: string;
};

type Transaction = {
  consent: boolean;
  connections: ConnectionDetail[];
};

type LocationTracker = {
  curr: PageValue;
  prev: PageValue;
};

type InitialState = {
  isGlobalLoading: boolean;
  isTester: boolean;
  selectedBank?: BankCode;
  flowFinished: boolean;
  prevLocation: PageValue;
  maxLocationId: number;
  loanGoal?: Goal;
  transactionsTab?: Window;
  requester: Requester;
  offers: Offer[];
  transactions: Transaction;
  offerTypes: ProductType[];
};

const initialState: InitialState = {
  isGlobalLoading: false,
  isTester: false,
  flowFinished: false,
  prevLocation: '/',
  maxLocationId: 0,
  requester: {},
  offers: [],
  offerTypes: [...productTypes],
  transactions: {
    consent: false,
    connections: [],
  },
};

type ActionName =
  | 'ADD_BANK_ACCOUNT'
  | 'REMOVE_TRANSACTION_ACCOUNT'
  | 'REMOVE_ALL_TRANSACTIONS'
  | 'SET_CONSENT'
  | 'SET_REQUESTER'
  | 'REMOVE_REQUESTER'
  | 'SET_OFFERS'
  | 'SET_IS_GLOBAL_LOADING'
  | 'SET_IS_TESTER'
  | 'SET_LOCATION'
  | 'SET_SELECTED_BANK'
  | 'SET_LOAN_GOAL'
  | 'SET_TRANSACTIONS_TAB'
  | 'SET_MAX_LOCATION'
  | 'SET_FLOW_FINISHED'
  | 'RESET_STATE'
  | 'SET_OFFER_TYPES';

type Action<T extends ActionName, V> = {
  type: T;
  value?: V;
};

type StoreActions = <T extends ActionName, V>(action: Action<T, V>) => void;

const storeActions = (dispatch: StoreActions) => ({
  setConsent: () => {
    dispatch({ type: 'SET_CONSENT', value: true });
  },
  setRequester: (requester: Requester) => {
    dispatch({ type: 'SET_REQUESTER', value: requester });
  },
  removeRequester: () => {
    dispatch({ type: 'REMOVE_REQUESTER' });
  },
  setIsGlobalLoading: (isLoading: boolean) => {
    dispatch({ type: 'SET_IS_GLOBAL_LOADING', value: isLoading });
  },
  addBankAccount: (account: ConnectionDetail) => {
    dispatch({ type: 'ADD_BANK_ACCOUNT', value: account });
  },
  setOffers: (offers: Offer[]) => {
    dispatch({ type: 'SET_OFFERS', value: offers });
  },
  removeTransactionAccount: (iban: string) => {
    dispatch({ type: 'REMOVE_TRANSACTION_ACCOUNT', value: iban });
  },
  removeAllTransactions: () => {
    dispatch({ type: 'REMOVE_ALL_TRANSACTIONS' });
  },
  setIsTester: (isTester: boolean) => {
    dispatch({ type: 'SET_IS_TESTER', value: isTester });
  },
  setLocation: ({ curr, prev }: LocationTracker) => {
    dispatch({ type: 'SET_LOCATION', value: { curr, prev } });
  },
  setMaxLocation: (id: number) => {
    dispatch({ type: 'SET_MAX_LOCATION', value: id });
  },
  setSelectedBank: (bankName?: BankCode) => {
    dispatch({ type: 'SET_SELECTED_BANK', value: bankName });
  },
  setLoanGoal: (goal: Goal) => {
    dispatch({ type: 'SET_LOAN_GOAL', value: goal });
  },
  setTransactionsTab: (tab: Window) => {
    dispatch({ type: 'SET_TRANSACTIONS_TAB', value: tab });
  },
  setFlowFinished: () => {
    dispatch({ type: 'SET_FLOW_FINISHED' });
  },
  resetState: () => {
    dispatch({ type: 'RESET_STATE' });
  },
  setOfferTypes: (type: ProductType[]) => {
    dispatch({ type: 'SET_OFFER_TYPES', value: type });
  },
});

const reducer = (state: InitialState, action: Action<ActionName, unknown>): InitialState => {
  switch (action.type) {
    case 'SET_IS_GLOBAL_LOADING': {
      const isGlobalLoading = action.value as boolean;

      return {
        ...state,
        isGlobalLoading,
      };
    }
    case 'SET_IS_TESTER': {
      const isTester = action.value as boolean;

      return {
        ...state,
        isTester,
      };
    }
    case 'SET_CONSENT': {
      const consent = action.value as boolean;

      const { transactions } = state;
      transactions.consent = consent;

      return {
        ...state,
        transactions,
      };
    }
    case 'SET_SELECTED_BANK': {
      const selectedBank = action.value as BankCode | undefined;

      return {
        ...state,
        selectedBank,
      };
    }
    case 'ADD_BANK_ACCOUNT': {
      const connection = action.value as ConnectionDetail;

      const newBankName = connection.bankName;
      const newAccounts = connection.accounts;

      const { transactions } = state;
      const { connections } = transactions;

      const bank = connections
        .find(({ bankName }) => bankName === newBankName);

      if (bank) {
        newAccounts.forEach((account) => {
          const newIban = account.iban;
          const exists = bank.accounts.some(({ iban }) => iban === newIban);

          if (exists) {
            return;
          }

          bank.accounts.push(account);
        });
      } else {
        connections.push(connection);
      }

      return {
        ...state,
        transactions,
      };
    }
    case 'SET_OFFERS': {
      const offers = action.value as Offer[];

      return {
        ...state,
        offers,
      };
    }
    case 'REMOVE_TRANSACTION_ACCOUNT': {
      const { transactions } = state;
      const { connections } = transactions;

      const iban = action.value;

      const bank = connections.find(
        (connection) => connection.accounts.some((account) => account.iban === iban));

      if (!bank) {
        return state;
      }

      const { accounts } = bank;
      bank.accounts = accounts.filter((account) => account.iban !== iban);

      const newConnections = { ...connections, bank };
      transactions.connections = newConnections;

      return {
        ...state,
        transactions,
      };
    }
    case 'REMOVE_ALL_TRANSACTIONS': {
      const { transactions } = state;
      transactions.connections = [];

      return {
        ...state,
        transactions,
      };
    }
    case 'SET_REQUESTER': {
      const value = action.value as Requester;
      const { requester } = state;

      return {
        ...state,
        requester: { ...requester, ...value },
      };
    }
    case 'REMOVE_REQUESTER': {
      return {
        ...state,
        requester: {},
      };
    }
    case 'SET_LOCATION': {
      const { curr, prev } = action.value as LocationTracker;
      const { maxLocationId } = state;

      const prevLocation = prev;
      const id = Math.max(restrictedPageOrder[curr] ?? 0, maxLocationId);

      return {
        ...state,
        maxLocationId: id,
        prevLocation,
      };
    }
    case 'SET_MAX_LOCATION': {
      const id = action.value as number;

      return {
        ...state,
        maxLocationId: id,
      };
    }
    case 'SET_FLOW_FINISHED': {
      return {
        ...state,
        flowFinished: true,
      };
    }
    case 'SET_LOAN_GOAL': {
      const goal = action.value as Goal;

      return {
        ...state,
        loanGoal: goal,
      };
    }
    case 'SET_TRANSACTIONS_TAB': {
      const tab = action.value as Window;

      return {
        ...state,
        transactionsTab: tab,
      };
    }
    case 'RESET_STATE': {
      return initialState;
    }
    case 'SET_OFFER_TYPES': {
      const offerTypes = action.value as ProductType[];

      return {
        ...state,
        offerTypes,
      };
    }
    default:
      return state;
  }
};

type Actions = ReturnType<typeof storeActions>;

const StoreContext = createContext<{ state: InitialState; actions: Actions } | null>(null);

type StoreProviderProps = {
  children: ReactNode;
};

function StoreProvider({ children }: StoreProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('state:', state);
  }

  // Everytime the CountProvider component re-renders a new reference
  // to object or array is being passed as value to
  // StateContext.Provider and hence even if the actual value may not
  // have changed, the Context consumers are re-rendered since the
  // reference check fails for the value
  //
  // https://stackoverflow.com/a/62231233
  const value = useMemo(() => {
    const actions = storeActions(dispatch);

    return { state, actions };
  }, [state]);

  return (
    <StoreContext.Provider value={value}>
      {children}
    </StoreContext.Provider>
  );
}

function useStoreContext() {
  const context = React.useContext(StoreContext);

  if (!context) {
    throw new Error('useStoreContext must be used within a storeProvider');
  }

  return context;
}

export { StoreProvider, useStoreContext };
