import { useEffect, useMemo, useRef, useState } from 'react';

function useHeight() {
  const frameId = useRef(0);
  const ref = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(0);

  const observer = useMemo(() => {
    if (typeof window === 'undefined') {
      return null;
    }

    return new ResizeObserver((entries) => {
      const entry = entries[0];

      if (!entry) {
        return;
      }

      cancelAnimationFrame(frameId.current);

      frameId.current = requestAnimationFrame(() => {
        const [size] = entry.borderBoxSize;

        if (!ref.current || !size) {
          return;
        }

        setHeight(size.blockSize);
      });
    });
  }, []);

  useEffect(() => {
    if (ref.current) {
      observer?.observe(ref.current);
    }

    return () => {
      observer?.disconnect();

      if (frameId.current) {
        cancelAnimationFrame(frameId.current);
      }
    };
  }, [ref.current]);

  return [ref, height] as const;
}

export default useHeight;
