import { v4 as uuid } from 'uuid';
import { storageKeys } from './utils/constants';
import { isBrowser } from './utils/helpers';

const getUserId = () => {
  if (!isBrowser) {
    return uuid();
  }

  const userId = localStorage.getItem(storageKeys.userId) ?? uuid();
  localStorage.setItem(storageKeys.userId, userId);

  return userId;
};

const splitConfig = {
  core: {
    authorizationKey: process.env.REACT_APP_SPLIT_IO,
    key: getUserId(),
  },
};

export default splitConfig;
