import React, { useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { sendMessage } from '../../services/contact-details';
import { buttonClick } from '../../utils/analytics';
import { getStatusError, transition } from '../../utils/helpers';
import ModalLayout from '../layout/ModalLayout';
import ErrorMessage from '../ui/ErrorMessage';
import FormButton from '../ui/FormButton';
import Header from '../ui/Header';
import Text from '../ui/Text';
import SendMessageForm, { type FormValue } from './SendMessageForm';

type SendMessageProps = {
  setIsOpen: (value: boolean) => void;
};

function SendMessage({ setIsOpen }: SendMessageProps) {
  const { t } = useTranslation();

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState<string>();

  const [isLoading, setIsLoading] = useState(false);
  const [succeeded, setSucceeded] = useState(false);

  const component = 'send_message';
  const formId = `${component}_form`;

  const { register, control, handleSubmit, formState } = useForm<FormValue>({ mode: 'onBlur' });
  const { errors, isValid } = formState;

  const onConfirm: SubmitHandler<FormValue> = async (data) => {
    setIsLoading(true);
    setError(undefined);

    const body = {
      name: `${data.first_name} ${data.surname}`,
      information: data.message,
      email: data.email,
      subject: data.product_type.title,
    };

    try {
      await sendMessage(body);

      setSucceeded(true);
    } catch (err) {
      setSucceeded(false);

      const errorKey = getStatusError(err);
      setError(errorKey);
    } finally {
      setIsLoading(false);
      setSubmitted(true);
    }
  };

  const onClose = () => {
    setIsOpen(false);

    const event = t('event.close', { component });
    buttonClick(event);
  };

  return (
    <ModalLayout>
      <ModalLayout.CloseButton
        onClose={onClose}
        name={`${component}_close`}
      />

      <motion.div
        className="flex flex-col items-center text-center lg:px-10"
        layout="position"
      >
        <Header
          langKey="text_65b98fc42cb7b100f5ccf223"
          size="h1"
          element="h2"
        />
        <Text
          langKey="text_65b98fc42cb7b100f5ccf224"
          className="max-w-lg"
        />
      </motion.div>

      <SendMessageForm
        register={register}
        control={control}
        errors={errors}
        onSubmit={handleSubmit(onConfirm)}
        formId={formId}
      />

      <FormButton
        success={succeeded}
        disabled={!isValid || succeeded}
        name={`${component}_submit`}
        formId={formId}
        label={t('text_65b98fc42cb7b100f5ccf231')}
        showIcon={submitted}
        isLoading={isLoading}
      />

      <ErrorMessage
        name="form_button_error"
        errorKey={error}
        useLayoutId
        className="text-center"
      />

      <motion.div
        layoutId="disclaimer_container"
        transition={transition}
        layout="preserve-aspect"
      >
        <Text
          langKey="text_65b98fc42cb7b100f5ccf232"
          size="xs"
          className="text-center"
        />
      </motion.div>
    </ModalLayout>
  );
}
export default SendMessage;
