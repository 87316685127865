import { useEffect } from 'react';

const BREVO_ID = '623d7388eca6a327515210e4';
const BREVO_URL = 'https://conversations-widget.sendinblue.com/sib-conversations.js';

declare const window: {
  SibConversations: ((args: unknown) => void) & { q?: unknown[] };
  SibConversationsID: string;
};

type Brevo = {
  shouldLoad: boolean;
};

const useBrevo = ({ shouldLoad }: Brevo) => {
  const id = 'brevo';

  const removeResource = (): void => {
    const externalResource = document.getElementById(id);
    const parent = externalResource?.parentNode;

    if (parent) {
      parent.removeChild(externalResource);
    }
  };

  useEffect(() => {
    const setArgs = (args: unknown) => {
      (window.SibConversations.q ??= []).push(args);
    };

    if (shouldLoad) {
      window.SibConversationsID = BREVO_ID;
      window.SibConversations ??= setArgs;

      const script = document.createElement('script');
      script.async = true;
      script.id = id;
      script.src = BREVO_URL;

      document.head.appendChild(script);
    }

    return () => removeResource();
  }, []);

  return removeResource;
};

export default useBrevo;
