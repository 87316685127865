import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../hooks/useIsMobile';
import Button from '../ui/Button';
import Divider from '../ui/Divider';
import Header from '../ui/Header';
import Link from '../ui/Link';
import Modal from '../ui/Modal';
import Text from '../ui/Text';
import MailIcon from '../../public/icons/raboIcons/mail.svg';
import PhoneIcon from '../../public/icons/raboIcons/phone.svg';
import Abla from '../../public/images/abla.jpg';
import Max from '../../public/images/max.jpg';
import Ralph from '../../public/images/ralph.jpg';
import SendMessage from './SendMessage';

function NeedHelp() {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <>
      <div className="flex-col hidden bg-white rounded lg:flex">
        <div className="px-8 py-5 border-b border-gray-medium">
          <Header element="h5" langKey="text_65b81a9dff3404009b604a81" />
        </div>

        {/* csc in sidebar */}
        <div className="flex flex-col gap-4 px-8 py-4">
          <div className="flex flex-row">
            <img src={String(Abla)} className="rounded-full w-14 h-14 border-2 border-white -mr-2" alt="Abla" />
            <img src={String(Max)} className="rounded-full w-14 h-14 border-2 border-white -mr-2" alt="Max" />
            <img src={String(Ralph)} className="rounded-full w-14 h-14 border-2 border-white" alt="Ralph" />
          </div>
          <Text
            langKey="text_66fabe6ce20b6c00cc174814"
            size="sm"
          />
          <Divider />
        </div>

        <div className="flex flex-col gap-4 px-8 py-4">
          <div className="flex gap-6">
            <PhoneIcon className="mt-2" />
            <div className="flex flex-col flex-1">
              <Link href={t('text_65d13d2a75e53400955c2708')}>
                <Text
                  langKey="create_call.title"
                  size="sm"
                  color="secondary"
                  weight="semibold"
                  className="underline hover:text-[inherit]"
                />
              </Link>
              <Text langKey="text_65b81a9dff3404009b604a83" size="sm" />
            </div>
          </div>

          <div className="flex gap-6">
            <MailIcon className="mt-2" />
            <div className="flex flex-col flex-1">
              <Button
                className="justify-start p-0 font-semibold underline hover:text-primary"
                label={t('send_message.title')}
                name="send_message_open_modal"
                color="secondary"
                type="submit"
                variant="link"
                onClick={() => setIsOpen(!isOpen)}
              />
              <Text langKey="text_65b81a9dff3404009b604a85" size="sm" />
            </div>
          </div>
        </div>
      </div>

      <Modal
        noFocus={isMobile}
        isOpen={isOpen}
        position="bottom"
        setIsOpen={setIsOpen}
        className="max-w-2xl"
      >
        <SendMessage setIsOpen={setIsOpen} />
      </Modal>
    </>
  );
}

export default NeedHelp;
