import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { measureLinkClick } from '../../utils/analytics';
import { pageMapper } from '../../utils/constants';
import { cn, joinValues } from '../../utils/helpers';
import Header from '../ui/Header';
import Link from '../ui/Link';
import Text from '../ui/Text';
import MoveRight from '../../public/icons/move_right.svg';
import Path1 from '../../public/icons/path_01.svg';
import Path2 from '../../public/icons/path_02.svg';
import Path3 from '../../public/icons/path_03.svg';
import Arrows from '../../public/icons/raboIcons/fast_arrows.svg';
import LegoBlocks from '../../public/icons/raboIcons/lego_blocks.svg';
import Invoice from '../../public/icons/raboIcons/urgent_invoice.svg';
import Maria from '../../public/images/maria.png';
import Accordion from './Accordion';

const measureFAQClick = (eventName: string, href: string) => {
  const url = new URL(href);
  const itemId = url.searchParams.get('active');

  measureLinkClick({ value: `navigation bar - ${eventName}[${itemId}]` });
};

const measureArticleClick = (href: string) => {
  const itemId = href.substring(href.lastIndexOf('/') + 1);
  measureLinkClick({ value: `navigation bar - artcile click -[${itemId}]` });
};

const questions = [
  {
    href: 'text_66f2c3c1bfeb3a85977804c1',
    linkLabel: 'text_66f26c2e7ba339010da66dbb',
  },
  {
    href: 'text_66f2c3c1bfeb3a85977804c2',
    linkLabel: 'text_66f26c2e7ba339010da66dbc',
  },
  {
    href: 'text_66f2c3c1bfeb3a85977804c3',
    linkLabel: 'text_66f26c2e7ba339010da66dbd',
  },
  {
    href: 'text_66f2c3c1bfeb3a85977804c4',
    linkLabel: 'text_66f26c2e7ba339010da66dbe',
  },
];

export function ProductItems() {
  const { t } = useTranslation();

  const items = [
    {
      title: 'text_66f26c2e7ba339010da66db1',
      description: 'text_66f26c2e7ba339010da66db2',
      linkLabel: 'text_66f26c2e7ba339010da66db3',
      href: 'text_66f2c3c1bfeb3a85977804ce',
      Logo: Invoice,
    },
    {
      title: 'text_66f26c2e7ba339010da66db4',
      description: 'text_66f26c2e7ba339010da66db5',
      linkLabel: 'text_66f26c2e7ba339010da66db6',
      href: 'text_66f2c4762c45da013794658b',
      Logo: LegoBlocks,
    },
    {
      title: 'text_66f26c2e7ba339010da66db7',
      description: 'text_66f26c2e7ba339010da66db8',
      linkLabel: 'text_66f26c2e7ba339010da66db9',
      href: 'text_66f2c3c1bfeb3a85977804cf',
      Logo: Arrows,
    },
  ];

  return (
    <div className="flex flex-col pb-5 gap-4 lg:flex-row lg:pb-0">
      {items.map(({ title, description, href, linkLabel, Logo }) => (
        <div
          className="relative flex flex-col p-4 gap-4 rounded bg-blue-cloud flex-grow lg:min-h-[250px] lg:max-h-[425px] lg:flex-1"
          key={title}
        >
          <Logo className="hidden absolute top-0 right-0 lg:flex" />

          <div className="flex flex-col lg:pt-36 xl:pt-32">
            <Header
              langKey={title}
              element="h4"
              size="md"
              weight="medium"
              className="leading-6 text-blue-background"
            />
            {description && (
              <Text
                weight="normal"
                size="sm"
                langKey={description}
                color="custom"
                className="text-gray-G900"
              />
            )}
          </div>
          <div className="flex items-center gap-2 mt-auto">
            <Link
              isExternal={false}
              weight="normal"
              className="text-blue-background font-[Inter] text-sm hover:underline hover:text-blue-background"
              href={t(href)}
              onClick={() => measureFAQClick(`navigation bar - ${t(title)} - click`, t(href))}
            >
              {t(linkLabel)}
            </Link>
            <MoveRight className="mb-px" />
          </div>
        </div>
      ))}

      <div className="flex flex-col gap-3 flex-1">
        <Header
          langKey="text_66f26c2e7ba339010da66dba"
          element="h4"
          size="md"
          weight="medium"
          className="leading-6 text-blue-background"
        />
        <div className="flex flex-col gap-2">
          {questions.map(({ href, linkLabel }) => (
            <Link
              key={href}
              isExternal={false}
              weight="normal"
              className="text-gray-G900 text-sm font-[Inter] hover:text-blue-background"
              href={t(href)}
              onClick={() => measureFAQClick('faq click', t(href))}
            >
              {t(linkLabel)}
            </Link>
          ))}
          <div className="flex items-center gap-2">
            <Link
              isExternal={false}
              weight="normal"
              className="text-blue-background text-sm font-[Inter] hover:text-blue-background hover:underline"
              href={pageMapper.faq}
              onClick={() => measureLinkClick({ value: 'Bekijk alle veelgestelde vragen click' })}
            >
              {t('text_66f26c2e7ba339010da66dbf')}
            </Link>
            <MoveRight className="mb-px" />
          </div>
        </div>
      </div>
    </div>
  );
}

export function KennisItems() {
  const { t } = useTranslation();

  const items = [
    {
      title: 'text_66f26c2e7ba339010da66dc4',
      linkLabel: 'text_66f26c2e7ba339010da66dc5',
      href: 'text_66f2c8a9f35720015536400c',
      Path: Path1,
    },
    {
      title: 'text_66f26c2e7ba339010da66dc6',
      linkLabel: 'text_66f26c2e7ba339010da66dc7',
      href: 'text_66f2c8b36c7cbd015615bdf9',
      Path: Path2,
    },
    {
      title: 'text_66f26c2e7ba339010da66dc8',
      linkLabel: 'text_66f26c2e7ba339010da66dc9',
      href: 'text_6716c0589cf31e00c10db6a3',
      Path: Path3,
    },
  ];

  const articles = [
    {
      href: 'text_66f2c3c1bfeb3a85977804c7',
      linkLabel: 'text_66f26c2e7ba339010da66dcb',
    },
    {
      href: 'text_66f2c3c1bfeb3a85977804c8',
      linkLabel: 'text_66f26c2e7ba339010da66dcc',
    },
    {
      href: 'text_66f2c3c1bfeb3a85977804c9',
      linkLabel: 'text_66f26c2e7ba339010da66dcd',
    },
  ];

  return (
    <div className="flex flex-col pb-5 gap-4 lg:flex-row lg:pb-0">
      {items.map(({ title, href, linkLabel, Path }) => (
        <div className="relative flex-1 rounded bg-blue-cloud z-10" key={title}>
          <div className="flex flex-col p-4 gap-4 h-full">
            <div className={cn('flex flex-col mt-auto lg:pt-24')}>
              <Header
                langKey={title}
                element="h4"
                size="md"
                weight="medium"
                className="leading-6 text-blue-background"
              />
            </div>
            <div className="flex items-center gap-2">
              <Link
                weight="normal"
                isExternal={false}
                className="text-blue-background font-[Inter] text-sm hover:text-blue-background hover:underline"
                href={t(href)}
                onClick={() => measureArticleClick(t(href))}
              >
                {t(linkLabel)}
              </Link>
              <MoveRight className="mb-px" />
            </div>
          </div>

          <Path className="hidden absolute top-0 w-full h-full pointer-events-none -z-10 lg:flex" />
        </div>
      ))}

      <div className="flex flex-col gap-4 p-4 flex-1">
        <Header
          langKey="text_66f26c2e7ba339010da66dca"
          element="h4"
          size="md"
          weight="medium"
          className="leading-6 text-blue-background"
        />
        <div className="flex flex-col gap-2">
          {articles.map(({ href, linkLabel }) => (
            <Link
              key={href}
              weight="normal"
              isExternal={false}
              className="text-gray-G900 font-[Inter] text-sm hover:text-blue-background"
              href={t(href)}
              onClick={() => measureArticleClick(t(href))}
            >
              {t(linkLabel)}
            </Link>
          ))}
        </div>
      </div>

    </div>
  );
}

type SupportItemsProps = {
  callback?: () => void;
};

export function SupportItems({ callback }: SupportItemsProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col pb-5 gap-4 w-full lg:flex-row lg:pb-0 lg:gap-6">
      <div className="flex flex-col gap-4 flex-1 lg:flex-col lg:gap-8 xl:flex-row xl:gap-6">

        {/* Section 1 */}
        <div className="flex flex-col gap-4 rounded bg-gray-background lg:bg-white flex-grow lg:flex-1 p-4 lg:p-0">
          <div className="flex flex-col gap-3">
            <Header
              langKey="text_66f26c2e7ba339010da66dd3"
              element="h4"
              size="md"
              weight="medium"
              className="leading-6 text-blue-background"
            />
            <div className="flex flex-col gap-2">
              {questions.map(({ href, linkLabel }) => (
                <Link
                  key={href}
                  isExternal={false}
                  weight="normal"
                  className="text-gray-G900 font-[Inter] text-sm hover:text-blue-background"
                  href={t(href)}
                  onClick={() => measureFAQClick('faq click', t(href))}
                >
                  {t(linkLabel)}
                </Link>
              ))}
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Link
              isExternal={false}
              weight="normal"
              className="text-blue-background font-[Inter] text-sm hover:text-blue-background hover:underline"
              href={pageMapper.faq}
              onClick={() => measureLinkClick({ value: 'Bekijk alle veelgestelde vragen click' })}
            >
              {t('text_66f26c2e7ba339010da66dbf')}
            </Link>
            <MoveRight className="mb-px" />
          </div>
        </div>

        {/* Section 2 */}
        <div className="flex flex-col gap-4 bg-gray-background lg:bg-white rounded flex-grow lg:flex-1 p-4 lg:p-0">
          <div className="flex flex-col gap-3">
            <Header
              langKey="text_66f26c2e7ba339010da66dd9"
              element="h4"
              size="md"
              weight="medium"
              className="leading-6 text-blue-background"
            />
            <Text
              weight="normal"
              size="sm"
              langKey="text_66f26c2e7ba339010da66dda"
              color="custom"
              className="text-gray-G900"
            />
          </div>
          <div className="flex items-center gap-2">
            <Link
              isExternal={false}
              weight="normal"
              className="text-blue-background font-[inter] text-sm hover:text-blue-background hover:underline"
              href={t('text_65d13d2a75e53400955c2708')}
              onClick={callback}
            >
              {t('text_66f26c2e7ba339010da66ddb')}
            </Link>
            <MoveRight className="mb-px" />
          </div>
          <div className="flex items-center gap-2">
            <Link
              weight="normal"
              isExternal={false}
              className="text-blue-background font-[Inter] text-sm hover:text-blue-background hover:underline"
              href={pageMapper.contact}
              onClick={callback}
            >
              {t('text_66f26c2e7ba339010da66ddc')}
            </Link>
            <MoveRight className="mb-px" />
          </div>
        </div>
      </div>

      {/* Section 3 */}
      <div className="relative flex flex-col pt-36 pb-6 px-6 rounded overflow-clip bg-cover bg-center-5 bg-handshake bg-no-repeat bg-blend-multiply flex-grow md:min-h-[300px] lg:min-h-[250px] lg:max-h-[440px] lg:max-w-[300px] lg:flex-1 lg:aspect-[16/9] lg:bg-200 xl:bg-100">
        <div className="flex flex-col gap-4 z-10 mt-auto">
          <Header
            langKey="text_66f26c2e7ba339010da66ddd"
            element="h4"
            size="md"
            weight="medium"
            color="white"
            className="leading-6"
          />
          <div className="flex items-center gap-2">
            <Link
              weight="normal"
              isExternal
              className="text-white font-[Inter] text-sm hover:text-white hover:underline"
              href={t('text_66f2c3c1bfeb3a85977804c5')}
              onClick={() => measureLinkClick({ value: 'navigation bar - become partner click' })}
            >
              {t('text_66f26c2e7ba339010da66dde')}
            </Link>
            <MoveRight className="mb-px stroke-white" />
          </div>
        </div>

        <div className="absolute inset-0 bg-gradient-to-b from-white/20 to-black/60 to-90%" />
      </div>

      {/* Section 4 */}
      <div className="flex flex-col p-6 gap-4 rounded bg-blue-cloud flex-grow lg:min-h-[250px] lg:max-h-[440px] lg:max-w-[300px] lg:flex-1">
        <img
          src={String(Maria)}
          alt="Maria"
          className="drop-shadow-lg w-full h-auto object-contain"
        />
        <div className="flex flex-col gap-3">
          <Header
            langKey="text_66f26c2e7ba339010da66ddf"
            element="h4"
            size="md"
            weight="medium"
            className="leading-6 text-blue-background"
          />
          <Text
            weight="normal"
            size="sm"
            langKey="text_66f26c2e7ba339010da66de0"
            color="custom"
            className="text-gray-G900"
          />
        </div>
        <div className="flex items-center gap-2 mt-auto">
          <Link
            isExternal
            weight="normal"
            className="text-blue-background font-[Inter] text-sm hover:text-blue-background hover:underline"
            href={t('text_66f2c3c1bfeb3a85977804c6')}
            onClick={() => measureLinkClick({ value: 'navigation bar - whitepaper click' })}

          >
            {t('text_66f26c2e7ba339010da66de1')}
          </Link>
          <MoveRight className="mb-px" />
        </div>
      </div>
    </div>
  );
}

type NavigationContentTestAProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  setActiveId: (value: number) => void;
};

const NavigationContentTestA = forwardRef<HTMLUListElement, NavigationContentTestAProps>(({
  isOpen,
  setActiveId,
  setIsOpen,
}, ref) => {
  const items = [
    {
      id: 1,
      header: 'text_66f26c2e7ba339010da66dad',
      Items: ProductItems,
    },
    {
      id: 2,
      header: 'text_66f26c2e7ba339010da66dae',
      Items: KennisItems,
    },
    {
      id: 3,
      header: 'text_66f26c2e7ba339010da66daf',
      Items: SupportItems,
    },
  ];

  const onHover = (id: number) => {
    setIsOpen(true);
    setActiveId(id);
  };

  return (
    <>
      {/* Mobile */}
      <nav
        className={joinValues({
          base: 'fixed flex flex-col gap-6 top-0 left-0 w-full h-screen pt-[74px] bg-white',
          desktop: 'lg:hidden',
          transition: 'transform delay-100 transition-all duration-300',
          open: isOpen ? '-translate-x-0' : 'delay-300 translate-x-full',
        })}
      >
        <ul className="relative flex flex-col items-start w-full px-6 overflow-auto">
          {items.map(({ header, Items }) => (
            <Accordion
              key={header}
              langKey={header}
              size="md"
              chevronType="ultra_thin"
              chevronColor="gray"
              variant="border"
              color="gray"
              weight="medium"
              className="navigationBar"
            >
              <Items callback={() => setIsOpen(false)} />
            </Accordion>
          ))}

          <div className="absolute top-0 left-0 right-0 h-px bg-white" />
        </ul>
      </nav>

      {/* Desktop */}
      <nav
        className={joinValues({
          base: 'hidden border-inherit',
          desktop: 'lg:flex',
        })}
      >
        <ul
          className="flex w-full gap-6 transition-colors duration-500 border-inherit"
          ref={ref}
        >
          {items.map(({ header, id }) => (
            <li key={id} onMouseEnter={() => onHover(id)}>
              <Text
                weight="medium"
                size="sm"
                langKey={header}
                color="gray"
                className="cursor-pointer hover:text-blue-background font-[Campton]"
              />
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
});

export default NavigationContentTestA;
