import { type AxiosPromise } from 'axios';
import api from '../utils/api';

type ContactDetailsV1 = {
  name: string;
  surname: string;
  email: string;
  phoneNumber: string;
  message?: string;
};

export function requestContact({
  name,
  surname,
  email,
  phoneNumber,
}: ContactDetailsV1): AxiosPromise {
  const path = 'dls-contact-details/v1/request-contact';

  const body = {
    name,
    surname,
    email,
    phoneNumber,
  };

  return api.post({ path, body });
}

type ContactFormV1 = {
  name: string;
  email: string;
  information: string;
  subject: string;
};

export function sendMessage({ name, information, email, subject }: ContactFormV1): AxiosPromise {
  const path = 'dls-contact-details/v1/contact-form';

  const body = {
    email,
    information,
    name,
    subject,
  };

  return api.post({ path, body });
}
