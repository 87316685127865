import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

function sentryInit() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      // Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/(dev|test|uat)-api\.project-railway\.nl(?:\/.*)?$/,
      /^https:\/\/financieren-zonder-jaarcijfers\.fund-r\.nl(?:\/.*)?$/,
      /^https:\/\/financieren-zonder-jaarcijfers\.rabobank\.nl(?:\/.*)?$/,
      /^https:\/\/api\.fund-r\.nl(?:\/.*)?$/,
    ],
    // replaysSessionSampleRate: 0,
    // replaysOnErrorSampleRate: 1.0,
  });
}

export default sentryInit;
