export default {
  isEligibleForDls: false,
  scoreIsRed: false,
  offers: [
    {
      id: 'cd646a54-372e-4890-815c-7366f10cf3b2',
      productType: 'PBL',
      term: '1',
      loanAmount: 10000.0,
      totalInterestCost: 598.3055555555554,
      linearMonthlyAmortization: 833.3333333333334,
      clientRate: 0.1086,
      creditProvision: 0.0,
    },
    {
      id: '3d8dc770-b261-4443-b108-fda05837c439',
      productType: 'PBL',
      term: '2',
      loanAmount: 10000.0,
      totalInterestCost: 1186.3916666666664,
      linearMonthlyAmortization: 416.6666666666667,
      clientRate: 0.1121,
      creditProvision: 0.0,
    },
    {
      id: '0ee50693-b3ee-4c7c-83c1-7d4b6a7c489b',
      productType: 'PBL',
      term: '3',
      loanAmount: 10000.0,
      totalInterestCost: 1770.5037037037034,
      linearMonthlyAmortization: 277.77777777777777,
      clientRate: 0.1131,
      creditProvision: 0.0,
    },
    {
      id: '9b364567-66c1-4cf6-86a3-6f2967a7af44',
      productType: 'PBL',
      term: '4',
      loanAmount: 10000.0,
      totalInterestCost: 2375.0319444444453,
      linearMonthlyAmortization: 208.33333333333334,
      clientRate: 0.11460000000000001,
      creditProvision: 0.0,
    },
    {
      id: '51bd915d-f1a6-40f8-bfcd-ea668bf51703',
      productType: 'PBL',
      term: '5',
      loanAmount: 10000.0,
      totalInterestCost: 2995.165000000001,
      linearMonthlyAmortization: 166.66666666666666,
      clientRate: 0.11610000000000001,
      creditProvision: 0.0,
    },
    {
      id: '27ebb1df-1754-41d7-a39d-f4122d5c6d33',
      productType: 'PBC',
      term: '',
      loanAmount: 10000.0,
      totalInterestCost: 2241.8590740740747,
      linearMonthlyAmortization: 0.0,
      clientRate: 0.0869,
      creditProvision: 2250.0,
    },
  ],
};
