import { useEffect, useState } from 'react';
import { DESKTOP_WIDTH } from './useIsDesktop';

export const TABLET_WIDTH = 640;

function useIsTablet() {
  const isGreater = window.innerWidth < DESKTOP_WIDTH;
  const isLess = window.innerWidth >= TABLET_WIDTH;

  const [isTablet, setIsTablet] = useState(isLess && isGreater);

  useEffect(() => {
    const handleChange = () => {
      setIsTablet(isLess && isGreater);
    };

    handleChange();

    window.addEventListener('resize', handleChange);

    return () => {
      window.removeEventListener('resize', handleChange);
    };
  }, []);

  return isTablet;
}

export default useIsTablet;
