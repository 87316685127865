import { useEffect, useState } from 'react';
import { type ConnectionDetail, getBankAccounts } from '../services/pbl-api';
import { storageKeys } from '../utils/constants';
import { getStatusError } from '../utils/helpers';
import { useStoreContext } from '../utils/store';

function useBankAccounts(shouldFetch = true) {
  const [connections, setConnections] = useState<ConnectionDetail[]>([]);

  const [error, setError] = useState<string>();
  const [isFinished, setIsFinished] = useState(false);

  const { actions } = useStoreContext();
  const { addBankAccount } = actions;

  const caseId = sessionStorage.getItem(storageKeys.caseId);

  useEffect(() => {
    if (!shouldFetch || !caseId) {
      return;
    }

    const getAccounts = async () => {
      setError(undefined);

      try {
        const { data } = await getBankAccounts({ caseId, inScopeForReconsent: true });
        const { connectionDetails } = data;

        setConnections(connectionDetails);
        connectionDetails.forEach(addBankAccount);
      } catch (err) {
        const errorKey = getStatusError(err);
        setError(errorKey);
      } finally {
        setIsFinished(true);
      }
    };

    getAccounts();
  }, [shouldFetch]);

  return { connections, error, isFinished };
}

export default useBankAccounts;
