import React, { type MouseEvent, type RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { buttonClick } from '../../utils/analytics';
import { pageMapper, type PageValue, restrictedPageOrder, storageKeys } from '../../utils/constants';
import { joinValues } from '../../utils/helpers';
import { isValueIn } from '../../utils/predicates';
import { useStoreContext } from '../../utils/store';
import Modal from '../ui/Modal';
import BackButtonMessage from './BackButtonMessage';

type Steps = {
  id: string;
  ref: RefObject<HTMLButtonElement>;
  url: PageValue;
  activeUrls: PageValue[];
};

type ProgressBarProps = {
  distance?: number;
  className?: string;
};

function ProgressBar({ className, distance }: ProgressBarProps) {
  const [activeStep, setActiveStep] = useState<RefObject<HTMLButtonElement>>();
  const { state, actions } = useStoreContext();

  const [showBackButtonMessage, setShowBackButtonMessage] = useState(false);
  const [destinationStep, setDestinationStep] = useState<Steps>();

  const { maxLocationId } = state;
  const { removeAllTransactions } = actions;

  const location = useLocation();
  const navigate = useNavigate();

  const component = 'progress_bar';
  const { t } = useTranslation();

  const position = Number(sessionStorage.getItem(storageKeys.progressBarPosition) ?? 0);
  const barWidth = Number(sessionStorage.getItem(storageKeys.progressBarWidth) ?? 0);

  const stepUrlMapper: Steps[] = [
    {
      id: 'about_you',
      ref: useRef<HTMLButtonElement>(null),
      url: pageMapper.aboutYou,
      activeUrls: [
        pageMapper.extraExpenses,
      ],
    },
    {
      id: 'transactions',
      ref: useRef<HTMLButtonElement>(null),
      url: pageMapper.transactions,
      activeUrls: [
        pageMapper.requestFailed,
      ],
    },
    {
      id: 'offer',
      ref: useRef<HTMLButtonElement>(null),
      url: pageMapper.offer,
      activeUrls: [
        pageMapper.offerSuccess,
        pageMapper.feedbackSuccess,
        pageMapper.getOffers,
      ],
    },
  ];

  const background = {
    x: position,
    width: barWidth,
  };

  const isStepActive = ({ url, activeUrls }: Steps): boolean => {
    const { pathname } = location;

    if (!isValueIn(pageMapper, pathname)) {
      return false;
    }

    return url === pathname || activeUrls.includes(pathname);
  };

  const setBackground = () => {
    const start = activeStep?.current?.offsetLeft;
    const width = activeStep?.current?.offsetWidth;

    if (start != null) {
      sessionStorage.setItem(storageKeys.progressBarPosition, start.toString());
    }

    if (width && width > 0) {
      sessionStorage.setItem(storageKeys.progressBarWidth, width.toString());
    }
  };

  const onClick = (
    event: MouseEvent<HTMLButtonElement>,
    step: Steps,
  ) => {
    setDestinationStep(step);
    const { ref, url } = step;

    const isOfferPage = location.pathname === pageMapper.offer;

    if (isOfferPage) {
      setShowBackButtonMessage(true);

      return;
    }

    buttonClick(event);
    setActiveStep(ref);

    navigate(url);
  };

  useEffect(() => {
    setActiveStep(stepUrlMapper.find(isStepActive)?.ref);
  }, [location]);

  return (
    <>
      <motion.div
        className={joinValues({
          base: 'relative flex justify-center w-full -z-10',
          options: className,
          lg: 'lg:z-0',
        })}
        layout="preserve-aspect"
      >
        <div className="z-10 flex justify-center p-1 rounded w-fit bg-gray-medium lg:w-full">
          <div className="relative flex">
            {stepUrlMapper.map((step) => {
              const { id, url, ref } = step;
              const locationOrder = restrictedPageOrder[url] ?? 0;

              const isDisabled = locationOrder > maxLocationId;
              const isActive = isStepActive(step);

              return (
                <button
                  className={joinValues({
                    base: 'py-1 px-2.5 text-xs font-semibold rounded',
                    animation: 'transition-all duration-300 delay-200',
                    active: activeStep && isActive ? 'text-blue-background' : 'text-gray-dark',
                  })}
                  aria-label={`${component}_${id}`}
                  key={id}
                  disabled={isDisabled}
                  type="button"
                  tabIndex={-1}
                  onClick={(event) => onClick(event, step)}
                  ref={ref}
                >
                  {t(`${component}.${id}`)}
                </button>
              );
            })}

            {activeStep?.current && (
              <motion.div
                className="text-blue-background absolute top-0 h-full bg-white rounded -z-10 "
                onAnimationComplete={setBackground}
                initial={barWidth > 0 ? background : false}
                animate={{
                  width: activeStep.current.offsetWidth,
                  x: activeStep.current.offsetLeft,
                }}
                transition={{ type: 'easeOut', duration: 0.2 }}
              />
            )}
          </div>
        </div>

        {/* Mobile background */}
        <div
          className={joinValues({
            base: 'w-full bg-gray-background',
            position: 'absolute top-0 left-0 bottom-1/2',
            shadow: distance === 1 && 'drop-shadow-rabobank',
            animation: 'transition-all duration-300',
            lg: 'lg:hidden',
          })}
        />
      </motion.div>

      <Modal
        isOpen={showBackButtonMessage}
        setIsOpen={setShowBackButtonMessage}
        position="bottom"
        className="max-w-2xl"
      >
        <BackButtonMessage
          setIsOpen={setShowBackButtonMessage}
          onGoBack={() => {
            if (!destinationStep) {
              return;
            }

            const { ref, url } = destinationStep;
            removeAllTransactions();

            buttonClick(t('event.back', { component: 'offer' }));
            setActiveStep(ref);

            navigate(url);
            setShowBackButtonMessage(false);
          }}
        />
      </Modal>
    </>
  );
}

export default ProgressBar;
