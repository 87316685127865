import { type AxiosPromise } from 'axios';
import api from '../utils/api';

type AccessTokenV2 = {
  token: string;
  caseId: string;
};

type GetJwtToken = {
  partnerId: string;
};

export function getJwtToken({ partnerId }: GetJwtToken): AxiosPromise<AccessTokenV2> {
  const path = 'dls-authentication/v3/authentication/pbl/anonymous-tokens';

  const headers = partnerId
    ? { originated: partnerId }
    : {};

  return api.post({ path, headers });
}

type GetResumeToken = {
  caseId: string;
  hash: string;
  kvkNumber: string;
};

export function getResumeToken({
  caseId,
  hash,
  kvkNumber,
}: GetResumeToken): AxiosPromise<AccessTokenV2> {
  const path = `dls-authentication/v2/case/${caseId}/authentication/resume-tokens`;

  const body = {
    passphrase: kvkNumber,
    hash,
  };

  return api.post({ path, body });
}
