import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../components/ui/Button';
import Dropdown from '../../components/ui/Dropdown';
import { isOptionDetail } from '../../components/ui/InputOptionView';
import { type Option } from './FaqItems';

type FaqPickerProps = {
  setSelectedOption: (option: Option) => void;
  options: Option[];
  selectedOption?: Option;
};

function FaqPicker({ selectedOption, setSelectedOption, options }: FaqPickerProps) {
  const { t } = useTranslation();
  const { control } = useForm();

  const component = 'faq';
  const inputName = 'faq_option';

  return (
    <>
      <Dropdown
        options={options}
        name={inputName}
        placeholder={t('text_65b972c5da355900be7c6baf')}
        displayValue={(value) => t(value.title)}
        control={control}
        className="lg:hidden"
        handleChange={(option) => isOptionDetail(option) && setSelectedOption(option)}
      />

      <div className="hidden w-full justify-between gap-8 px-4 lg:flex">
        {options.map((option) => {
          const { title, id } = option;
          const isSelected = title === selectedOption?.title;

          return (
            <Button
              name={`${component}_${title}`}
              label={t(title)}
              key={id}
              size="sm"
              color="secondary"
              variant={isSelected ? 'base' : 'outline'}
              onClick={() => {
                if (isSelected) {
                  return;
                }

                setSelectedOption(option);
              }}
            />
          );
        })}
      </div>
    </>
  );
}

export default FaqPicker;
