import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { buttonClick } from '../../utils/analytics';
import ModalLayout from '../layout/ModalLayout';
import Button from '../ui/Button';
import Divider from '../ui/Divider';
import Header from '../ui/Header';
import Text from '../ui/Text';
import SignPost from '../../public/icons/sign_post.svg';

type BackButtonMessageProps = {
  setIsOpen: (value: boolean) => void;
  onGoBack: () => void;
};

function BackButtonMessage({ setIsOpen, onGoBack }: BackButtonMessageProps) {
  const { t } = useTranslation();
  const component = 'back_button_message';

  const onClose = () => {
    setIsOpen(false);

    const event = t('event.close', { component });
    buttonClick(event);
  };

  return (
    <ModalLayout>
      <div className="flex flex-col items-center gap-4 lg:gap-10">
        <SignPost />

        <motion.div
          className="flex flex-col items-center text-center lg:px-10"
          layout="position"
        >
          <Header
            langKey="text_66f5e15c32661400f38b995b"
            size="h1"
            element="h2"
          />
          <Text
            langKey="text_66f5e15c32661400f38b995c"
            className="max-w-lg"
          />
        </motion.div>
      </div>

      <Divider />

      <div className="flex flex-col w-full items-center gap-4 lg:flex-row-reverse lg:justify-end">
        <Button
          label={t('text_66f5e15c32661400f38b995d')}
          name="go_back"
          color="primary"
          className="w-full flex-1"
          onClick={onGoBack}
        />
        <Button
          label={t('text_66f5e15c32661400f38b995e')}
          name="show_options"
          color="secondary"
          variant="outline"
          className="w-full flex-1"
          onClick={onClose}
        />
      </div>
    </ModalLayout>
  );
}
export default BackButtonMessage;
