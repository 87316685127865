import { type ConnectionDetail } from '../../services/pbl-api';
import { toDateString } from '../../utils/helpers';
import { requester1 } from '../../e2e/fixtures/requester';

const FIVE_DAYS = 5;
const YEAR_IN_DAYS = 365;

export function createDate(period: 'past' | 'near_future' | 'future'): string {
  const date = new Date();

  if (period === 'past') {
    date.setDate(date.getDate() - YEAR_IN_DAYS / 2);
  }

  if (period === 'near_future') {
    date.setDate(date.getDate() + FIVE_DAYS);
  }

  if (period === 'future') {
    date.setDate(date.getDate() + YEAR_IN_DAYS / 2);
  }

  return toDateString(date) ?? '';
}

const connections: ConnectionDetail[] = [
  {
    needReconsent: true,
    connectionId: '1',
    consentId: '1',
    consentExpiresAt: createDate('past'),
    bankName: 'RABOBANK',
    accounts: [
      {
        clientName: requester1.name,
        iban: 'NL00RABO0000000001',
        matchState: 'MATCH',
      },
      {
        clientName: requester1.name,
        iban: 'NL00RABO0000000002',
        matchState: 'COULD_NOT_MATCH',
      },
    ],
  },
  {
    needReconsent: true,
    connectionId: '2',
    consentId: '2',
    consentExpiresAt: createDate('near_future'),
    bankName: 'ABN',
    accounts: [
      {
        clientName: requester1.name,
        iban: 'NL00ABNA0000000003',
        matchState: 'MATCH',
      },
      {
        clientName: requester1.name,
        iban: 'NL00ABNA0000000004',
        matchState: 'COULD_NOT_MATCH',
      },
    ],
  },
  {
    needReconsent: false,
    connectionId: '3',
    consentId: '3',
    consentExpiresAt: createDate('future'),
    bankName: 'ING',
    accounts: [
      {
        clientName: requester1.name,
        iban: 'NL00INGB0000000005',
        matchState: 'MATCH',
      },
      {
        clientName: requester1.name,
        iban: 'NL00INGB0000000006',
        matchState: 'COULD_NOT_MATCH',
      },
    ],
  },
];

export default connections;
