export const BV_ABBREVIATION = 'b.v.';

export const pageMapper = {
  home: '/',
  zakelijkeLening: '/zakelijke-lening',
  kortlopendeLening: '/kortlopende-lening',
  zakelijkKrediet: '/zakelijk-krediet',
  kortlopendKrediet: '/kortlopend-krediet',
  faq: '/veelgestelde-vragen',
  aboutYou: '/jouw-aanvraag',
  extraExpenses: '/jouw-aanvraag/extra-uitgaven',
  contact: '/contact',
  cookies: '/cookies',
  transactions: '/jouw-aanvraag/transacties-uploaden',
  getTransactions: '/jouw-aanvraag/transacties-verzamelen',
  getOffers: '/jouw-aanvraag/get-offers',
  offer: '/jouw-aanvraag/persoonlijk-aanbod',
  offerSuccess: '/jouw-aanvraag/volgende-stappen',
  requestFailed: '/jouw-aanvraag/request-failed',
  feedbackSuccess: '/jouw-aanvraag/feedback-success',
  transactionSuccess: '/jouw-aanvraag/transaction-success',
  reconsent: '/reconsent',
  getReconsent: '/reconsent/get-consent',
  redirection: '/redirection',
  kennisbank: '/kennisbank',
  kennisbankOndernemen: '/kennisbank/ondernemen',
  kennisbankFinancieleBegrippen: '/kennisbank/financiele-begrippen',
  kennisbankFinancieringsmogelijkheden: '/kennisbank/financieringsmogelijkheden',
  kennisbankOndernemenDebiteuren: '/kennisbank/ondernemen/debiteurenbeheer',
  kennisbankOndernemenDebiteurenBnr: '/kennisbank/ondernemen/debiteurenbeheerbnr',
  kennisbankOndernemenEcommerce: '/kennisbank/ondernemen/jouw-shop-klaar-voor-het-najaar',
  kennisbankOndernemenVoorraadBeheer: '/kennisbank/ondernemen/voorraad-beheer',
  kennisbankOndernemenGezondeKlantrelatie: '/kennisbank/ondernemen/5-tips-voor-een-gezonde-klantrelatie',
  kennisbankOndernemenOnvoorzienekosten: '/kennisbank/ondernemen/hoe-ga-je-met-onvoorziene-kosten-om-als-ondernemer',
  kennisbankOndernemenGeneratieVerschillen: '/kennisbank/ondernemen/generatieverschillen-in-zakelijke-betalingen-en-facturering',
  kennisbankOndernemenNegativeBKR: '/kennisbank/ondernemen/Kun-je-als-ondernemer-geld-lenen-met-een-negatieve-bkr-registratie',
  kennisbankFinancieleBegrippenWerkkapitaal: '/kennisbank/financiele-begrippen/werkkapitaal',
  kennisbankFinancieleBegrippenLiquiditeitVerbeteren: '/kennisbank/financiele-begrippen/liquiditeit-verbeteren',
  kennisbankFinancieleBegrippenRoodStaan: '/kennisbank/financiele-begrippen/rood-staan-of-niet',
  kennisbankFinancieleBegrippenWatIsBtw: '/kennisbank/financiele-begrippen/wat-is-btw',
  kennisbankFinancieringsmogelijkhedenMkbFinanciering: '/kennisbank/financieringsmogelijkheden/mkb-financieringen',
  kennisbankFinancieringsmogelijkhedenVoorraadFinancieren: '/kennisbank/financieringsmogelijkheden/voorraad-financieren',
  kennisbankFinancieringsmogelijkhedenWerkkapitaalfinanciering: '/kennisbank/financieringsmogelijkheden/werkkapitaalfinanciering',
  kennisbankFinancieringsmogelijkhedenKansOpSuccesvolleAanvraag: '/kennisbank/financieringsmogelijkheden/kans-op-succesvolle-financieringsaanvraag-vergroten',
} as const;

export type ArticleCategories = 'Ondernemen' | 'Financiele-begrippen' | 'Financieren';

export type KennisbankArticle = {
  id: number;
  url: string;
  title: string;
  description: string;
  category: ArticleCategories;
  creationDate: Date;
  image: string;
};

export const ArticleMapper: Record<string, KennisbankArticle> = {
  debiteuren: { id: 0, url: pageMapper.kennisbankOndernemenDebiteuren, title: 'text_66eacb5ab12af800ab104020', description: 'text_66eacb5ab12af800ab104021', category: 'Ondernemen', creationDate: new Date('2024-06-01'), image: 'debiteuren.jpg' },
  Werkkapitaal: { id: 1, url: pageMapper.kennisbankFinancieleBegrippenWerkkapitaal, title: 'text_668fcc7f8e2df15689fa8894', description: 'text_668fcc7f8e2df15689fa8895', category: 'Financiele-begrippen', creationDate: new Date('2024-07-01'), image: 'werkkapitaal.jpg' },
  MkbFinanciering: { id: 2, url: pageMapper.kennisbankFinancieringsmogelijkhedenMkbFinanciering, title: 'text_669f6997ccaf6500a503173e', description: 'text_669f6997ccaf6500a503173f', category: 'Financiele-begrippen', creationDate: new Date('2024-07-01'), image: 'mkb.jpg' },
  VoorraadFinancieren: { id: 3, url: pageMapper.kennisbankFinancieringsmogelijkhedenVoorraadFinancieren, title: 'text_669fb39ca957fc0149310502', description: 'text_669fb39ca957fc0149310503', category: 'Financieren', creationDate: new Date('2024-07-01'), image: 'voorraadfinancieren.jpg' },
  Liquidteit: { id: 4, url: pageMapper.kennisbankFinancieleBegrippenLiquiditeitVerbeteren, title: 'text_66a1091d694b00004818aee1', description: 'text_66a1091d694b00004818aee2', category: 'Financiele-begrippen', creationDate: new Date('2024-07-01'), image: 'liquidity.jpg' },
  VoorraadBeheer: { id: 5, url: pageMapper.kennisbankOndernemenVoorraadBeheer, title: 'text_66a0b703a883b0006eaea85a', description: 'text_66a0b703a883b0006eaea85b', category: 'Ondernemen', creationDate: new Date('2024-08-01'), image: 'voorraadbeheer.jpg' },
  Btw: { id: 6, url: pageMapper.kennisbankFinancieleBegrippenWatIsBtw, title: 'text_66a0b7584717570143c732c5', description: 'text_66a0b7584717570143c732c6', category: 'Financiele-begrippen', creationDate: new Date('2024-08-01'), image: 'btw.jpg' },
  Ecommerce: { id: 7, url: pageMapper.kennisbankOndernemenEcommerce, title: 'text_66b9cda405076000998b812c', description: 'text_66b9cda405076000998b812d', category: 'Ondernemen', creationDate: new Date('2024-08-01'), image: 'ecommerce.jpg' },
  Werkkapitaalfinanciering: { id: 8, url: pageMapper.kennisbankFinancieringsmogelijkhedenWerkkapitaalfinanciering, title: 'text_66bb5b0b453ce487de2b217a', category: 'Financieren', description: 'text_66bb5b0b453ce487de2b217b', creationDate: new Date('2024-09-01'), image: 'werkkapitaal.jpg' },
  Roodstaan: { id: 9, url: pageMapper.kennisbankFinancieleBegrippenRoodStaan, title: 'text_66bcaa0a49e80700d8268d93', description: 'text_66bcaa0a49e80700d8268d94', category: 'Financiele-begrippen', creationDate: new Date('2024-09-01'), image: 'roodstaan.jpg' },
  NegatieveBkr: { id: 10, url: pageMapper.kennisbankOndernemenNegativeBKR, title: 'text_6718d4a1aed3ab150b1acf51', description: 'text_6718d4a1aed3ab150b1acf52', category: 'Ondernemen', creationDate: new Date('2024-10-30'), image: 'negativeBKR.jpg' },
} as const;

export type PageValue = typeof pageMapper[keyof typeof pageMapper];

export const restrictedPageOrder: Partial<Record<PageValue, number>> = {
  [pageMapper.offerSuccess]: -1,
  [pageMapper.feedbackSuccess]: -1,
  [pageMapper.aboutYou]: 1,
  [pageMapper.extraExpenses]: 2,
  [pageMapper.transactions]: 3,
  [pageMapper.getTransactions]: 3,
  [pageMapper.getOffers]: 4,
  [pageMapper.offer]: 5,
};

export const utmSources = {
  partner: 'partner',
};

export const storageKeys = {
  jwt: 'jwt',
  correlationId: 'correlation_id',
  partnerId: 'partner_id',
  navigationHeight: 'navigation_height',
  caseId: 'case_id',
  progressBarWidth: 'bar_width',
  progressBarPosition: 'bar_position',
  hasVisited: 'has_visited',
  userId: 'user_id',
  isPartner: 'is_partner',
};

export const featureFlags = {
  customBanner: 'pbl_banner_maintenance',
  busyBanner: 'pbl_busy_banner',
};
