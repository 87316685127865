import React, { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { PostHogProvider } from 'posthog-js/react';
import useBrevo from '../hooks/useBrevo';
import useExternalResource from '../hooks/useExternalResource';
import { StoreProvider } from '../utils/store';
import ScrollToTop from '../components/container/ScrollToTop';
import i18n from '../i18n';
import posthogConfig from '../posthog.config';
import Routes from '../router/Routes';
import AppGate from './AppGate';
import AppSetup from './AppSetup';

import '../styles/globals.css';

const isProduction = process.env.NODE_ENV === 'production';

function App() {
  useBrevo({
    shouldLoad: isProduction,
  });

  useExternalResource({
    shouldLoad: isProduction,
    id: 'tealium',
    href: 'https://www.rabobank.nl/wapi/tag-management-system/loaders',
  });

  return (
    <I18nextProvider i18n={i18n}>
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={posthogConfig}
      >
        <StoreProvider>
          <HelmetProvider>
            <Router>
              <ScrollToTop />
              <AppSetup>
                <AppGate>
                  {/* TODO: add proper loading */}
                  <Suspense fallback={<div>...loading</div>}>
                    <Routes />
                  </Suspense>
                </AppGate>
              </AppSetup>
            </Router>
          </HelmetProvider>
        </StoreProvider>
      </PostHogProvider>
    </I18nextProvider>
  );
}

export default App;
