import React, { type ReactNode, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { AnimatePresence, motion } from 'framer-motion';

import 'react-popper-tooltip/dist/styles.css';
import '../../styles/tooltip.css';

type TooltipProps = {
  children: ReactNode;
  content: ReactNode;
  className?: string;
};

function Tooltip({ children, content, className }: TooltipProps) {
  const [isVisible, setIsVisible] = useState(false);

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
  } = usePopperTooltip({
    interactive: true,
    delayHide: 100,
    visible: isVisible,
    onVisibleChange: setIsVisible,
    delayShow: 300,
    trigger: 'hover',
    placement: 'auto',
  });

  return (
    <div ref={setTriggerRef} className={className}>
      {content}

      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            ref={setTooltipRef}
            {...getTooltipProps({
              className: 'tooltip-container backdrop-blur',
            })}
          >
            <div className="text-white text-xs leading-5 font-thin">
              {children}
            </div>
            <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Tooltip;
