import { toNumber } from './helpers';

/**
 * An email is a string (a subset of ASCII characters) separated into two
 * parts by @ symbol. a "personal_info" and a domain, that is
 * personal_info@domain. The length of the personal_info part may be up to
 * 64 characters long and domain name may be up to 253 characters.
 *
 * link: https://stackoverflow.com/a/46181
 *
 * @param email       Value from the input to be validated
 * @returns           A boolean to indicate the validation status of the
 *                    email.
 */
export function isValidEmail(email: string) {
  const value = email.trim().toLowerCase();

  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
}

export function isValidPhonenumber(number: string) {
  const value = number?.replace(/ /g, '');

  return /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/gm.test(value);
}

export const isValidName = (value: string) => /^[a-zA-ZÀ-ž\s.-]*$/.test(value);

export const isValidCurrency = (value: string) => /^\d+(,\d{1,2})?$/.test(value);

export const isValidAmount = (amount: string, minValue: number, maxValue: number) => {
  const value = toNumber(amount);

  return value >= minValue && value <= maxValue;
};

export const isMinimumAmount = (amount: string, minValue: number) => {
  const value = toNumber(amount);

  return value >= minValue;
};

export const isRoundedToThousand = (amount?: string) => {
  const value = toNumber(amount);

  return !Number.isNaN(value) && value % 1000 === 0;
};
