import React, { useMemo } from 'react';
import type { FieldValues } from 'react-hook-form';
import { cn } from '../../utils/helpers';
import InputOptionView, { type InputOptionViewProps, type OptionDetail } from './InputOptionView';

type DropdownProps<T extends OptionDetail, K extends FieldValues> = InputOptionViewProps<T, K> & {
  hasDynamicOptions?: boolean;
};

function Dropdown<T extends OptionDetail, K extends FieldValues>({
  hasDynamicOptions,
  ...props
}: DropdownProps<T, K>) {
  const { options, className, disabled } = props;

  // The combobox needs to open the option view when the options has changed. This is
  // not the case for a dropdown with a fixed number of options. If the options is changed
  // dynamically, then 'hasDynamicOptions' should be set to true
  const dropdownOptions = hasDynamicOptions
    ? options
    : useMemo(() => options, []);

  return (
    <InputOptionView
      {...props}
      className={cn(className, !disabled && 'cursor-pointer')}
      readOnly
      options={dropdownOptions}
    />
  );
}

export default Dropdown;
