import React from 'react';
import { motion } from 'framer-motion';
import { cn, transition } from '../../utils/helpers';
import CheckmarkIcon from '../../public/icons/checkmark_circle.svg';
import CrossIcon from '../../public/icons/cross_circle.svg';
import Button from './Button';

type FormButtonProps = {
  className?: string;
  success?: boolean;
  disabled?: boolean;
  name: string;
  formId: string;
  label: string;
  isLoading: boolean;
  showIcon?: boolean;
};

function FormButton({
  className,
  success,
  disabled,
  name,
  formId,
  label,
  isLoading,
  showIcon,
}: FormButtonProps) {
  const icon = success
    ? <CheckmarkIcon className="w-6 fill-success" />
    : <CrossIcon className="w-6 fill-error" />;

  return (
    <motion.div
      className={cn('flex gap-4 w-full lg:justify-end', className)}
      layoutId="form_button"
      layout="position"
      transition={transition}
    >
      {showIcon && (
        <motion.div
          className="flex justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          {icon}
        </motion.div>
      )}
      <Button
        label={label}
        name={`${name}_submit`}
        className="w-full transition-all duration-300 lg:w-fit"
        form={formId}
        color="primary"
        type="submit"
        disabled={success || disabled}
        isLoading={isLoading}
      />
    </motion.div>
  );
}

export default FormButton;
