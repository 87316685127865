import React, { type FC, useState } from 'react';
import { useStoreContext } from '../../utils/store';
import Overview from './Overview';
import Verification from './Verification';

export type ReconsentView =
  | 'verification'
  | 'transaction_overview';

const reconsentMapper: { [K in ReconsentView]: FC<{ setView: (value: ReconsentView) => void }> } = {
  verification: Verification,
  transaction_overview: Overview,
};

function Reconsent() {
  const { state } = useStoreContext();

  const { requester } = state;
  const { kvkNumber } = requester;

  const component: ReconsentView = kvkNumber
    ? 'transaction_overview'
    : 'verification';

  const [view, setView] = useState(component);

  const Component = reconsentMapper[view] ?? Verification;

  return (
    <Component setView={setView} />
  );
}
export default Reconsent;
