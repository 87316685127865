import { featureFlags } from '../utils/constants';
import useSplit from './useSplit';

const useMaintenanceBanner = () => {
  const { customBanner } = featureFlags;
  const { isReady, treatment, config } = useSplit(customBanner);

  return {
    isReady,
    showBanner: treatment !== 'off',
    message: config.message,
  };
};

export default useMaintenanceBanner;
