import React from 'react';
import Header from '../ui/Header';
import Text from '../ui/Text';
import Accordion from './Accordion';

type TextContent = {
  header: string;
  text: string;
};

type ItemsProps = TextContent & {
  offset: number;
};

function Items({ header, text, offset }: ItemsProps) {
  return (
    <Accordion
      langKey={header}
      size="sm"
      weight="semibold"
      chevronType="thin"
      variant="border"
      color="black"
      padding="px-6 lg:px-8"
      bottomOffset={offset}
    >
      <Text
        className="px-6 pb-5 lg:px-8"
        size="sm"
        langKey={text}
      />
    </Accordion>
  );
}

type QuestionsProps = {
  header: string;
  items: TextContent[];
  bottomOffset: number;
};

function Questions({
  bottomOffset,
  items,
  header,
}: QuestionsProps) {
  const content = items.map((item) => (
    <Items key={item.header} {...item} offset={bottomOffset} />),
  );

  return (
    <>
      <div className="hidden bg-white lg:block lg:rounded">
        <Header
          className="px-8 py-5"
          element="h3"
          size="h5"
          langKey={header}
        />

        {content}
      </div>

      <Accordion
        langKey={header}
        size="h5"
        chevronType="thin"
        variant="border"
        className="lg:hidden"
        padding="px-6"
        bottomOffset={bottomOffset}
      >
        {content}
      </Accordion>
    </>
  );
}

export default Questions;
