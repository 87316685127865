import React, { forwardRef, type ReactNode, useRef } from 'react';
import { joinValues, transition } from '../../utils/helpers';
import ComponentLayout from './ComponentLayout';

type StickyButtonLayoutProps = {
  className?: string;
  children: ReactNode;
};

const StickyButtonLayout = forwardRef<HTMLDivElement, StickyButtonLayoutProps>(({
  className,
  children,
}, ref) => {
  const localRef = useRef<HTMLDivElement>(null);
  const frameRef = ref ?? localRef;

  return (
    <ComponentLayout
      layoutId="sticky_button"
      transition={transition}
      ref={frameRef}
      className={joinValues({
        base: 'flex-col py-6 bg-white sticky bottom-0',
        border: 'border-t border-gray-medium',
        sm: 'sm:items-center',
        lg: 'lg:hidden',
        options: className,
      })}
    >
      {children}
    </ComponentLayout>
  );
});

export default StickyButtonLayout;
