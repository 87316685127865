import React from 'react';
import { motion } from 'framer-motion';
import { cn, transition } from '../../utils/helpers';
import Button from '../ui/Button';
import ErrorMessage from '../ui/ErrorMessage';

type SubmitButtonProps = {
  label: string;
  name: string;
  form?: string;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  error?: string;
  onClick?: () => void;
};

function SubmitButton({
  label,
  name,
  form,
  className,
  disabled,
  isLoading,
  error,
  onClick,
}: SubmitButtonProps) {
  const type = form ? 'submit' : 'button';

  return (
    <motion.div
      className={cn('flex flex-col items-center w-full gap-2 lg:items-end', className)}
      layout="position"
      transition={transition}
    >
      <Button
        name={`${name}_submit`}
        label={label}
        form={form}
        type={type}
        disabled={disabled}
        isLoading={isLoading}
        color="primary"
        className="w-full lg:w-fit"
        onClick={onClick}
      />
      <ErrorMessage
        name={`${name}_error`}
        errorKey={error}
        className="text-center lg:text-right"
      />
    </motion.div>
  );
}

export default SubmitButton;
