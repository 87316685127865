import React, { forwardRef, type ReactNode, useRef } from 'react';
import { Dialog } from '@headlessui/react';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import useIsMobile from '../../hooks/useIsMobile';
import { joinValues, transition } from '../../utils/helpers';

type Position =
  | 'center'
  | 'top'
  | 'bottom'
  | 'left'
  | 'right';

const positionMapper = {
  center: 'items-center justify-center m-4',
  top: 'items-center justify-start',
  bottom: 'items-center justify-end',
  left: 'items-start justify-center',
  right: 'items-end justify-center',
};

const positionOptions = {
  center: 'rounded',
  top: 'rounded-bl-2xl rounded-br-2xl',
  bottom: 'rounded-tl-2xl rounded-tr-2xl',
  left: 'rounded-tr-2xl rounded-br-2xl',
  right: 'rounded-tl-2xl rounded-bl-2xl',
};

const initialPosition = {
  center: { scale: 0.75, opacity: 0 },
  top: { y: '-100%' },
  bottom: { y: '100%' },
  left: { x: '-100%' },
  right: { x: '100%' },
};

type ModalProps = {
  position?: Position;
  isOpen: boolean;
  className?: string;
  noFocus?: boolean;
  children: ReactNode;
  setIsOpen?: (value: boolean) => void;
};

const Modal = forwardRef<HTMLDivElement, ModalProps>(({
  isOpen,
  children,
  className,
  noFocus,
  setIsOpen = () => {},
  position = 'center',
}, ref) => {
  const localRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();

  return (
    <LayoutGroup id="modal">
      <AnimatePresence>
        {isOpen && (
          <Dialog
            onClose={setIsOpen}
            initialFocus={localRef}
            open
            className={joinValues({
              base: 'fixed inset-0 flex flex-col z-30',
              position: positionMapper[position],
              sm: 'sm:items-center sm:justify-center sm:py-10 sm:m-0 z-30',
            })}
          >
            <Dialog.Overlay
              as={motion.div}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
              }}
              exit={{
                opacity: 0,
                transition: { duration: 0.3, ease: 'easeIn' },
              }}
              className="fixed inset-0 bg-black/40 backdrop-blur-md"
            />

            <motion.div
              transition={transition}
              layoutId="modal"
              ref={ref}
              className={joinValues({
                base: 'w-full max-sm:max-h-[80%] z-10',
                custom: className,
                sm: 'sm:rounded',
                options: positionOptions[position],
                scroll: 'overflow-auto',
              })}
            >
              <motion.div
                initial={isMobile ? initialPosition[position] : initialPosition.center}
                animate={{
                  x: 0,
                  y: 0,
                  opacity: 1,
                  scale: 1,
                }}
                exit={{
                  transition: { duration: 0.3, ease: 'easeIn' },
                  ...(isMobile ? initialPosition[position] : initialPosition.center),
                }}
                ref={noFocus ? localRef : null}
                transition={transition}
                className={joinValues({
                  base: 'bg-white p-6 shadow-xl',
                  lg: 'lg:p-10',
                })}
              >
                {children}
              </motion.div>
            </motion.div>
          </Dialog>
        )}
      </AnimatePresence>
    </LayoutGroup>
  );
});

export default Modal;
