import type { GetTransactionsResponse } from '../../services/pbl-api';

export const status = {
  iban: 'NL00RABO0000000001',
  psd2StatusV1: 'STORAGE',
  username: '1b28b6e2-9d01-4b35-9c14-1525155b1045',
  bank: 'RABO',
  trackingId: '1b28b6e2-9d01-4b35-9c14-1525155b1045',
};

export const customerId = '926108919934229314';

export const transactionInfo: GetTransactionsResponse = {
  accounts: [
    {
      clientName: 'Rabobank Nederland B.V.',
      iban: 'NL80RABO1127000002',
      matchState: 'MATCH',
    },
    {
      clientName: 'Rabobank Nederland B.V.',
      iban: 'NL80RABO1127000003',
      matchState: 'COULD_NOT_MATCH',
    },
    {
      clientName: 'Rabobank Nederland B.V.',
      iban: 'NL80RABO1127000004',
      matchState: 'NO_MATCH',
    },
  ],
  bank: 'RABOBANK',
  sub: '1b28b6e2-9d01-4b35-9c14-1525155b1045',
};

export const connections = [
  {
    id: '983437195480144072',
    secret: 'v4BSB81N04K57bjM1UQB7vnY_bSZ5QzYJywTkfi3Ops',
    provider_id: '4183',
    provider_code: 'abn_amro_oauth_client_nl_xf',
    provider_name: 'ABN AMRO (Sandbox NL)',
    daily_refresh: true,
    customer_id: '926108919934229314',
    created_at: '2023-04-06T09:40:25Z',
    updated_at: '2023-04-06T09:40:43Z',
    last_success_at: '2023-04-06T09:40:43Z',
    status: 'active',
    country_code: 'XF',
    next_refresh_possible_at: '2023-04-06T09:55:43Z',
    show_consent_confirmation: false,
    last_consent_id: '983437196050569419',
    matchState: 'MATCH',
  },
  {
    id: '925472880282572881',
    secret: 'oypYIeoOZj2VnBoiK65rNAFYHl3hhnodKdWWK4d0V3c',
    provider_id: '3843',
    provider_code: 'rabobank_oauth_client_nl_xf',
    provider_name: 'Rabobank (Sandbox)',
    daily_refresh: false,
    customer_id: '926108919934229314',
    created_at: '2023-03-31T14:56:46Z',
    updated_at: '2023-03-31T14:58:52Z',
    last_success_at: '2023-03-31T14:58:52Z',
    status: 'active',
    country_code: 'XF',
    next_refresh_possible_at: '2023-03-31T15:13:52Z',
    show_consent_confirmation: false,
    last_consent_id: '979247724148824179',
    matchState: 'COULD_NOT_MATCH',
  },
  {
    id: '925472880282572880',
    secret: 'oypYIeoOZj2VnBoiK65rNAFYHl3hhnodKdWWK4d0V2c',
    provider_id: '3834',
    provider_code: 'rabobank_oauth_client_nl_xf',
    provider_name: 'Rabobank (Sandbox)',
    daily_refresh: false,
    customer_id: '926108919934229314',
    created_at: '2023-03-31T14:56:41Z',
    updated_at: '2023-03-31T14:58:46Z',
    last_success_at: '2023-03-31T14:58:46Z',
    status: 'active',
    country_code: 'XF',
    next_refresh_possible_at: '2023-03-31T15:13:46Z',
    show_consent_confirmation: false,
    last_consent_id: '979247724148824178',
    matchState: 'NO_MATCH',
  },
];
