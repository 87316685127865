const reasonMapper = {
  caseIdNotFound: 'Case ID not available',
};

type Reason = keyof typeof reasonMapper;

class AuthError extends Error {
  constructor(reason: Reason) {
    const message = reasonMapper[reason];
    super(message);

    this.name = 'auth_error';
  }
}

export default AuthError;
