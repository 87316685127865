import React, { type ReactNode } from 'react';
import { cn, joinValues } from '../../utils/helpers';

const fontColors = {
  secondary: 'text-white',
  white: 'text-secondary',
  blue: 'text-white',
};

const borderColors = {
  secondary: 'border-transparent',
  white: 'border-secondary',
  blue: 'border-transparent',
};

const colors = {
  secondary: 'bg-secondary',
  white: 'bg-white',
  blue: 'bg-blue-500',
};

export type CircleTextColor = keyof typeof colors;

type CircleTextProps = {
  className?: string;
  disabled?: boolean;
  color?: CircleTextColor;
  children: ReactNode;
};

function CircleText({ children, className, disabled, color = 'secondary' }: CircleTextProps) {
  const options = cn(
    className,
    borderColors[color],
    colors[color],
    fontColors[color],
  );

  return (
    <div
      className={joinValues({
        base: 'flex justify-center items-center pt-px',
        frame: 'rounded-full h-6 w-6 flex-shrink-0',
        font: 'font-semibold text-[0.75rem] leading-[0.875rem]',
        border: 'border',
        option: cn(className, !disabled && options),
        disabled: disabled && 'bg-white border-gray-light text-gray-light',
      })}
    >
      {children}
    </div>
  );
}

export default CircleText;
