import React, { type FormEvent } from 'react';
import type { Control, FieldErrors, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isValidEmail } from '../../utils/validation';
import Dropdown from '../ui/Dropdown';
import Input from '../ui/Input';
import type { OptionDetail } from '../ui/InputOptionView';
import TextArea from '../ui/TextArea';

const inputNames = {
  firstName: 'first_name',
  email: 'email',
  surname: 'surname',
  productType: 'product_type',
  message: 'message',
} as const;

type ValueType = {
  [inputNames.firstName]: string;
  [inputNames.email]: string;
  [inputNames.surname]: string;
  [inputNames.productType]: OptionDetail;
  [inputNames.message]: string;
};

export type FormValue = {
  [V in typeof inputNames[keyof typeof inputNames]]: ValueType[V];
};

type SendMessageFormProps = {
  register: UseFormRegister<FormValue>;
  errors: FieldErrors<FormValue>;
  control: Control<FormValue>;
  onSubmit: (event: FormEvent) => void;
  formId: string;
  isDisabled?: boolean;
};

function SendMessageForm({
  register,
  control,
  errors,
  onSubmit,
  formId,
  isDisabled,
}: SendMessageFormProps) {
  const { t } = useTranslation();

  const {
    firstName,
    email,
    surname,
    productType,
    message,
  } = inputNames;

  const options = [
    {
      id: 'zakelijke_lening',
      title: t('text_65bb4994daa95500b1e5f183'),
    },
    {
      id: 'zakelijk_krediet',
      title: t('text_65bb4999fb015000e2c7e828'),
    },
  ];

  return (
    <form
      onSubmit={onSubmit}
      id={formId}
      className="flex flex-col gap-4"
    >
      <div className="flex gap-2">
        <Input
          placeholder={t('text_65b98fc42cb7b100f5ccf226')}
          label={t('text_65b98fc42cb7b100f5ccf225')}
          disabled={isDisabled}
          className="w-full"
          error={errors[firstName]?.message}
          {...register(firstName, {
            required: t('error.required'),
          })}
        />
        <Input
          placeholder={t('text_65b98fc42cb7b100f5ccf228')}
          label={t('text_65b98fc42cb7b100f5ccf227')}
          disabled={isDisabled}
          className="w-full"
          error={errors[surname]?.message}
          {...register(surname, {
            required: t('error.required'),
          })}
        />
      </div>
      <Input
        type="email"
        placeholder={t('text_65b98fc42cb7b100f5ccf22a')}
        label={t('text_65b98fc42cb7b100f5ccf229')}
        disabled={isDisabled}
        error={errors[email]?.message}
        {...register(email, {
          required: t('error.required'),
          validate: {
            [email]: (value) => isValidEmail(value) || t('error.email'),
          },
        })}
      />
      <Dropdown
        name={productType}
        options={options}
        placeholder={t('text_65b98fc42cb7b100f5ccf22c')}
        label={t('text_65b98fc42cb7b100f5ccf22b')}
        displayValue={(value) => value.title}
        error={errors[productType]?.message}
        control={control}
        disabled={isDisabled}
        rules={{
          required: t('error.required'),
        }}
      />
      <TextArea
        label={t('text_65b98fc42cb7b100f5ccf22e')}
        error={errors[message]?.message}
        placeholder={t('text_65b98fc42cb7b100f5ccf22f')}
        maxLength={160}
        disabled={isDisabled}
        {...register(message, {
          required: t('error.required'),
        })}
      />
    </form>
  );
}

export default SendMessageForm;
