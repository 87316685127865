import axios, { type AxiosPromise, type AxiosRequestConfig } from 'axios';
import { storageKeys } from './constants';

type ApiHeader = AxiosRequestConfig['headers'];

const timeout = 60 * 1000;

const apiKey = process.env.REACT_APP_API_KEY;
const apiUrl = process.env.REACT_APP_API_URL;

const getAuthHeaders = () => {
  const jwt = sessionStorage.getItem(storageKeys.jwt);
  const correlationId = sessionStorage.getItem(storageKeys.correlationId);

  const headers: ApiHeader = { 'x-api-key': apiKey };

  if (jwt) {
    headers.authorization = `bearer ${jwt}`;
    headers.cognito = 'enabled';
  }

  if (correlationId) {
    headers['x-state-correlation-id'] = correlationId;
  }

  return headers;
};

const createConfig = (
  method: string,
  path: string,
  headers?: ApiHeader,
): AxiosRequestConfig => {
  const url = `${apiUrl}/${path}`;
  const authHeaders = getAuthHeaders();

  const config = {
    method,
    crossDomain: true,
    url,
    timeout,
    headers: {
      ...authHeaders,
      ...headers,
    },
  };

  return config;
};

type GetConfig = {
  headers?: ApiHeader;
  path: string;
};

const get = <T>({ path, headers }: GetConfig): AxiosPromise<T> => {
  const config = createConfig('GET', path, headers);

  return axios<T>(config);
};

type PostConfig = {
  headers?: ApiHeader;
  path: string;
  body?: unknown;
};

const post = <T>({ path, body, headers }: PostConfig): AxiosPromise<T> => {
  const config = createConfig('POST', path, headers);

  if (body) {
    config.data = body;
  }

  return axios<T>(config);
};

type PutConfig = {
  headers?: ApiHeader;
  path: string;
  data?: unknown;
};

const put = ({ path, data, headers }: PutConfig) => {
  const config = createConfig('PUT', path, headers);

  if (data) {
    config.data = data;
  }

  return axios(config);
};

type RemoveConfig = {
  headers?: ApiHeader;
  path: string;
  data: unknown;
};

const remove = ({ path, data, headers }: RemoveConfig) => {
  const config = createConfig('DELETE', path, headers);

  if (data) {
    config.data = data;
  }

  return axios(config);
};

export default {
  get,
  post,
  put,
  remove,
};
