import { useEffect } from 'react';

type ExternalResource = {
  id: string;
  href: string;
  shouldLoad: boolean;
};

function useExternalResource({ shouldLoad, href, id }: ExternalResource): () => void {
  const removeResource = (): void => {
    const externalResource = document.getElementById(id);
    const parent = externalResource?.parentNode;

    if (parent) {
      parent.removeChild(externalResource);
    }
  };

  useEffect(() => {
    if (shouldLoad) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = id;
      script.src = href;

      document.head.appendChild(script);
    }

    return () => removeResource();
  }, []);

  return removeResource;
}

export default useExternalResource;
