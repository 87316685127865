import React, { type FC, type SVGAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { buttonClick } from '../../utils/analytics';
import { joinValues } from '../../utils/helpers';
import Header from '../ui/Header';
import Text from '../ui/Text';

type ButtonCardProps = {
  onClick: () => void;
  title: string;
  text: string;
  Icon: FC<SVGAttributes<SVGElement>>;
  className?: string;
};

function ButtonCard({ onClick, title, text, Icon, className }: ButtonCardProps) {
  const { t } = useTranslation();
  const component = 'button_card';

  const handleOnClick = () => {
    onClick();

    const event = t(`event.${component}.click`, { component: title });
    buttonClick(event);
  };

  return (
    <button
      className={joinValues({
        base: 'flex flex-col p-6 gap-4 bg-white text-left',
        hover: 'hover:shadow-rabobank-md',
        animation: 'transition-shadow duration-300',
        border: 'border rounded border-gray-medium',
        options: className,
      })}
      type="button"
      name={`${component}_${title}`}
      aria-label={`${component}_${title}`}
      onClick={handleOnClick}
    >
      <Icon />
      <div className="flex flex-col">
        <Header element="h4">{title}</Header>
        <Text>{text}</Text>
      </div>
    </button>
  );
}

export default ButtonCard;
