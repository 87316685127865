import React, { type ChangeEvent, forwardRef, type KeyboardEvent, useState } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';
import { cn } from '../../utils/helpers';
import { type BaseInputProps, InputContainer, type InputContainerProps } from './Input';

type TextAreaProps = Omit<InputContainerProps, 'children'> & BaseInputProps & UseFormRegisterReturn & {
  autoComplete?: string;
  rows?: number;
};

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(({
  autoComplete = 'on',
  rows,
  readOnly,
  autofill,
  placeholder,
  onFocus,
  error,
  variant,
  className,
  label,
  ...register
}, ref) => {
  const { name, maxLength, onChange, disabled } = register;
  const [count, setCount] = useState(0);

  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (maxLength) {
      setCount(event?.target?.value?.length ?? 0);
    }

    onChange(event);
  };

  return (
    <InputContainer
      className={className}
      error={error}
      label={label}
      variant={variant}
      name={name}
      disabled={disabled}
      showErrorIcon={false}
    >
      <div className="flex flex-col w-full">
        <textarea
          data-testid={name}
          id={name}
          rows={rows}
          placeholder={placeholder}
          autoComplete={autoComplete}
          readOnly={readOnly}
          {...register}
          ref={ref}
          onChange={handleOnChange}
          onFocus={onFocus}
          className={cn(
            'w-full py-3 px-4 resize-none scroll-auto outline-none',
            autofill ? 'bg-blue-autocomplete' : 'bg-transparent',
          )}
          onKeyDown={(event: KeyboardEvent<HTMLTextAreaElement>) => {
            switch (event.code) {
              case 'Space': {
                const { value } = (event.target as HTMLTextAreaElement);
                const endsWithSpace = value?.endsWith(' ');

                if (endsWithSpace) {
                  event.preventDefault();
                }

                break;
              }
              case 'Enter':
              case 'NumpadEnter':
                event.preventDefault();
                break;
              default: break;
            }
          }}
        />
        {Boolean(maxLength) && (
          <div className="text-right text-xs font-normal text-gray-dark py-1 px-2 font-[Inter]">
            {`${count}/${maxLength}`}
          </div>
        )}
      </div>
    </InputContainer>
  );
});

export default TextArea;
