import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { pageMapper } from '../utils/constants.ts';
import { setCorrelationId } from '../utils/helpers.ts';

const useInterceptor = () => {
  const [tokenExpired, setTokenExpired] = useState(false);

  const pathToSkipAuth = [
    pageMapper.reconsent,
  ];

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const resInterceptor = (res) => {
      setCorrelationId(res);

      return res;
    };

    const errInterceptor = (error) => {
      const shouldSkip = pathToSkipAuth.some((path) => pathname.includes(path));

      if (!shouldSkip) {
        setTokenExpired(error.response.status === 401);
      }

      return Promise.reject(error);
    };

    const interceptor = axios.interceptors.response.use(resInterceptor, errInterceptor);

    return () => axios.interceptors.response.eject(interceptor);
  }, []);

  return [tokenExpired, setTokenExpired];
};

export default useInterceptor;
