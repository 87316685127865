import { useEffect, useRef } from 'react';

const useOutsideClick = (callback) => {
  const ref = useRef(null);

  useEffect(() => {
    const clickOutside = (event) => {
      if (ref?.current?.contains(event.target)) {
        return;
      }

      callback();
    };

    document.addEventListener('touchstart', clickOutside);
    document.addEventListener('mousedown', clickOutside);

    return () => {
      document.removeEventListener('touchstart', clickOutside);
      document.removeEventListener('mousedown', clickOutside);
    };
  }, [ref]);

  return ref;
};

export default useOutsideClick;
