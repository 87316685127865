import { useContext } from 'react';
import { SplitContext, useTreatments } from '@splitsoftware/splitio-react';
import { z } from 'zod';

const schema = z.object({
  message: z.string(),
});

const useSplit = (featureFlag?: string) => {
  if (!featureFlag) {
    return {
      isReady: true,
      treatment: 'off',
      config: { message: '' },
    };
  }

  const treatments = useTreatments([featureFlag]);
  const { treatment, config } = treatments[featureFlag] ?? {};

  const { isReady } = useContext(SplitContext);

  if (!config) {
    return {
      isReady,
      treatment,
      config: { message: '' },
    };
  }

  const parsedConfig = JSON.parse(config);
  const zodData = schema.parse(parsedConfig);

  return { isReady, treatment, config: zodData };
};

export default useSplit;
