import React, { type ReactNode } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { cn } from '../../utils/helpers';

const colors = {
  primary: 'stroke-primary',
  secondary: 'stroke-secondary',
  white: 'stroke-white',
  black: 'stroke-off-black',
};

type LoaderColor = keyof typeof colors;

type LoaderProps = {
  strokeWidth?: number;
  duration?: number;
  color?: LoaderColor;
};

function Loader({
  strokeWidth = 4,
  duration = 1,
  color = 'secondary',
}: LoaderProps) {
  const viewBox = `0 0 ${90 + strokeWidth} ${90 + strokeWidth}`;

  return (
    <AnimatePresence>
      <motion.div
        className="absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full"
        key="loading_circle_container"
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration }}
      >
        <svg
          viewBox={viewBox}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width="150%"
          height="150%"
          className="absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2"
        >
          <motion.circle
            cx="50%"
            cy="50%"
            r={45}
            className={cn('fill-transparent', colors[color])}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            animate={{
              pathLength: 0.15,
              rotate: 360,
              opacity: 1,
            }}
            transition={{
              repeat: Infinity,
              duration,
              ease: 'linear',
            }}
          />
        </svg>
      </motion.div>
    </AnimatePresence>
  );
}

type LoadingCricleProps = {
  children: ReactNode;
};

function LoadingCircle({ children }: LoadingCricleProps) {
  return (
    <div className="relative">
      {children}
    </div>
  );
}

LoadingCircle.Loader = Loader;

export default LoadingCircle;
