import React, { forwardRef, type ReactNode, useRef } from 'react';
import { type AnimationProps, motion } from 'framer-motion';
import { joinValues } from '../../utils/helpers';

const variants = {
  base: 'px-6',
  wide: 'px-3',
  full: 'px-0',
};

export type Variant = keyof typeof variants;

type ComponentLayoutProps = {
  variant?: Variant;
  children: ReactNode;
  layoutId?: string;
  className?: string;
  transition?: AnimationProps['transition'];
};

const ComponentLayout = forwardRef<HTMLDivElement, ComponentLayoutProps>(({
  className,
  children,
  layoutId,
  transition,
  variant = 'base',
}, ref) => {
  const localRef = ref ?? useRef(null);

  return (
    <motion.div
      transition={transition}
      layoutId={layoutId}
      ref={localRef}
      className={joinValues({
        base: 'flex',
        lg: !className?.includes('lg:px') && 'lg:px-20',
        xl: !className?.includes('xl:px') && 'xl:px-32',
        options: className,
        variant: variants[variant],
      })}
    >
      {children}
    </motion.div>
  );
});

export default ComponentLayout;
