import { useEffect } from 'react';
import {
  measureArticleCustomerRelation,
  measureArticleDebtors,
  measureArticleDebtorsBnr,
  measureArticleEcommerce,
  measureArticleGenerationDifferences,
  measureArticleImproveLiquidity,
  measureArticleInventoryFinancing,
  measureArticleInventoryManagement,
  measureArticleNegativeBKR,
  measureArticleOverdraft,
  measureArticleSmeFinancing,
  measureArticleSuccesfullRequest,
  measureArticleUnforeseenCosts,
  measureArticleVat,
  measureArticleWorkingCapital1,
  measureArticleWorkingCapital2,
  measureBusinessCredit,
  measureBusinessLoan,
  measureCollectTransactions,
  measureContact,
  measureEligibilityModal,
  measureExtraExpenses,
  measureFaq,
  measureFeedbackSuccess,
  measureGetOffers,
  measureGetReconsent,
  measureHomepage,
  measureKennisbank,
  measureNextSteps,
  measureOfferOptions,
  measureOfferOverview,
  measureReconsentOverview,
  measureReconsentVerification,
  measureRedirection,
  measureRequestFailed,
  measureStartYourApplication,
  measureTransactionConsent,
  measureTransactionsAddAccount,
  measureTransactionsOverview,
  measureTransactionSuccess,
  measureYourApplication,
} from '../utils/analytics';
import useSplit from './useSplit';

const measureMapper = {
  homepage: measureHomepage,
  businessLoan: measureBusinessLoan,
  businessCredit: measureBusinessCredit,
  startYourApplication: measureStartYourApplication,
  yourApplication: measureYourApplication,
  extraExpenses: measureExtraExpenses,
  transactionConsent: measureTransactionConsent,
  transactionsAddAccount: measureTransactionsAddAccount,
  redirection: measureRedirection,
  transactionSuccess: measureTransactionSuccess,
  collectTransactions: measureCollectTransactions,
  transactionsOverview: measureTransactionsOverview,
  getOffers: measureGetOffers,
  offerOptions: measureOfferOptions,
  offerOverview: measureOfferOverview,
  nextSteps: measureNextSteps,
  feedbackSuccess: measureFeedbackSuccess,
  faq: measureFaq,
  contact: measureContact,
  getReconsent: measureGetReconsent,
  reconsentOverview: measureReconsentOverview,
  reconsentVerification: measureReconsentVerification,
  requestFailed: measureRequestFailed,
  eligibilityModal: measureEligibilityModal,
  kennisbank: measureKennisbank,
  kennisbankOndernemenDebiteuren: measureArticleDebtors,
  kennisbankOndernemenDebiteurenBnr: measureArticleDebtorsBnr,
  kennisbankFinancieleBegrippenWerkkapitaal: measureArticleWorkingCapital1,
  kennisbankFinancieringsmogelijkhedenMkbFinanciering: measureArticleSmeFinancing,
  kennisbankFinancieringsmogelijkhedenLiquiditeitVerbeteren: measureArticleImproveLiquidity,
  kennisbankFinancieringsmogelijkhedenVoorraadFinancieren: measureArticleInventoryFinancing,
  kennisbankOndernemenVoorraadBeheer: measureArticleInventoryManagement,
  kennisbankFinancieleBegrippenWatIsBtw: measureArticleVat,
  kennisbankOndernemenEcommerce: measureArticleEcommerce,
  kennisbankFinancieringsmogelijkhedenWerkkapitaalFinanciering: measureArticleWorkingCapital2,
  kennisbankFinancieleBegrippenRoodStaan: measureArticleOverdraft,
  kennisbankFinancieringsmogelijkhedenKansOpSuccesvolleAanvraag: measureArticleSuccesfullRequest,
  kennisbankOndernemenGezondeKlantrelatie: measureArticleCustomerRelation,
  kennisbankOndernemenOnvoorzienekosten: measureArticleUnforeseenCosts,
  kennisbankOndernemenGeneratieVerschillen: measureArticleGenerationDifferences,
  kennisbankOndernemenNegativeBKR: measureArticleNegativeBKR,
} as const;

type MeasureName = keyof typeof measureMapper;

function usePageMeasure(name: MeasureName, value?: string) {
  // commented code is placeholder for future feature flag
  // const { navigationBar } = featureFlags;
  const { isReady, treatment } = useSplit(
    // navigationBar,
  );

  useEffect(() => {
    if (!isReady) {
      return;
    }

    measureMapper[name](treatment, value);
  }, [isReady, treatment]);
}

export default usePageMeasure;
