import { useLocation } from 'react-router-dom';
import { featureFlags, pageMapper, type PageValue } from '../utils/constants';
import useSplit from './useSplit';

const skippedLocationsMaintenance: PageValue[] = [
  pageMapper.home,
  pageMapper.kortlopendKrediet,
  pageMapper.zakelijkKrediet,
  pageMapper.kortlopendeLening,
  pageMapper.zakelijkeLening,
  pageMapper.aboutYou,
];

const skippedLocationsHomepage: PageValue[] = [];

const useBanner = () => {
  const location = useLocation();
  const { pathname } = location;

  const { homepageBanner, maintenanceBanner } = featureFlags;

  const {
    isReady: isHomepageReady,
    treatment: homepageTreatment,
    config: homepageConfig,
  } = useSplit(homepageBanner);

  const {
    isReady: isMaintenanceReady,
    treatment: maintenanceTreatment,
    config: maintenanceConfig,
  } = useSplit(maintenanceBanner);

  const showHomepageBanner = homepageTreatment !== 'off';
  const showMaintenanceBanner = maintenanceTreatment !== 'off';

  const isReady = isMaintenanceReady || isHomepageReady;
  const message = showHomepageBanner ? homepageConfig.message : maintenanceConfig.message;

  const skippedLocations = showHomepageBanner
    ? skippedLocationsHomepage : skippedLocationsMaintenance;

  const showBanner = isReady
    && (showHomepageBanner || showMaintenanceBanner)
    && !skippedLocations.includes(pathname)
    && !pathname.includes('kennisbank')
    && !pathname.includes(pageMapper.reconsent);

  return {
    showBanner,
    message,
  };
};

export default useBanner;
