import React, { useState } from 'react';
import { LayoutGroup, motion } from 'framer-motion';
import usePageMeasure from '../../hooks/usePageMeasure';
import { transition } from '../../utils/helpers';
import ContactCards from '../../components/container/ContactCards';
import Footer from '../../components/container/Footer';
import ComponentLayout from '../../components/layout/ComponentLayout';
import Header from '../../components/ui/Header';
import PageTitle from '../../components/ui/PageTitle';
import Text from '../../components/ui/Text';
import FaqItems, { type Option } from './FaqItems';
import FaqPicker from './FaqPicker';

const page = 'faq';

function Faq() {
  const [selectedOption, setSelectedOption] = useState<Option>();
  usePageMeasure('faq');

  const options: Option[] = [
    {
      id: 1,
      title: 'text_65b972c5da355900be7c6bb0',
      items: [
        'item13',
        'item28',
        'item14',
        'item15',
        'item24',
        'item26',
        'item27',
        'item136',
        'item137',
      ],
    },
    {
      id: 2,
      title: 'text_65b972c5da355900be7c6bcb',
      items: [
        'item29',
        'item74',
        'item30',
        'item31',
        'item32',
        'item33',
        'item136',
        'item137',
      ],
    },
    {
      id: 3,
      title: 'text_65b972c5da355900be7c6bd6',
      items: [
        'item16',
        'item17',
      ],
    },
    {
      id: 4,
      title: 'text_65b972c5da355900be7c6bdb',
      items: [
        'item18',
        'item19',
        'item20',
        'item21',
        'item22',
        'item23',
        'item6',
      ],
    },
  ];

  return (
    <LayoutGroup id={page}>
      <PageTitle title="text_65b818c13045ed00dcdb9e21" description="text_65b818c13045ed00dcdb9e23" />

      <ComponentLayout
        className="flex-col py-10 gap-10 w-full lg:gap-20 lg:py-20 lg:items-center"
      >
        <motion.div
          className="flex flex-col w-full gap-10 lg:gap-20 xl:px-10 2xl:px-28"
          layout="position"
        >
          <div className="flex flex-col w-full gap-8 lg:items-center lg:gap-10">
            <Header
              className="lg:text-5xl"
              langKey="text_65b972c5da355900be7c6bae"
              size="h2"
              element="h1"
            />

            <FaqPicker
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              options={options}
            />
          </div>

          <FaqItems
            selectedOption={selectedOption}
            options={options}
          />
        </motion.div>
      </ComponentLayout>

      <ComponentLayout
        transition={transition}
        layoutId="contact"
        className="flex-col bg-gray-background py-10 gap-10 lg:items-center lg:py-20"
      >
        <div className="flex flex-col gap-2 text-center">
          <Header
            langKey="text_65b972c5da355900be7c6bbf"
            element="h3"
          />
          <Text
            langKey="text_65b972c5da355900be7c6bc0"
          />
        </div>

        <ContactCards />
      </ComponentLayout>

      <Footer />
    </LayoutGroup>
  );
}

export default Faq;
