import React, { type ReactNode, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useBanner from '../../hooks/useBanner';
import { buttonClick } from '../../utils/analytics';
import { pageMapper, storageKeys } from '../../utils/constants';
import { cn, joinValues } from '../../utils/helpers';
import ComponentLayout from '../layout/ComponentLayout';
import HamburgerButton from '../ui/HamburgerButton';
import RabobankLogo from '../../public/icons/rabobank_logo.svg';
import Banner from './Banner';
import ProgressBar from './ProgressBar';

const navigationColor = {
  primary: {
    logo: 'fill-primary',
    text: 'text-primary',
    hamburger: 'primary',
    border: 'border-primary',
  },
  secondary: {
    logo: 'fill-secondary',
    text: 'text-secondary',
    hamburger: 'secondary',
    border: 'border-secondary',
  },
  white: {
    logo: 'fill-white',
    text: 'text-white',
    hamburger: 'white',
    border: 'border-white',
  },
  black: {
    logo: 'fill-off-black',
    text: 'text-off-black',
    hamburger: 'black',
    border: 'border-off-black',
  },
} as const;

export const navigationBackground = {
  transparent: {
    base: 'bg-transparent',
    scroll: 'bg-white',
  },
  white: {
    base: 'bg-white',
    scroll: 'bg-white',
  },
  gray: {
    base: 'bg-gray-background',
    scroll: 'bg-gray-background',
  },
};

export const navigationTheme = {
  PRIMARY_PRIMARY: {
    base: navigationColor.primary,
    scroll: navigationColor.primary,
  },
  PRIMARY_SECONDARY: {
    base: navigationColor.primary,
    scroll: navigationColor.secondary,
  },
  PRIMARY_WHITE: {
    base: navigationColor.primary,
    scroll: navigationColor.white,
  },
  PRIMARY_BLACK: {
    base: navigationColor.primary,
    scroll: navigationColor.black,
  },
  SECONDARY_SECONDARY: {
    base: navigationColor.secondary,
    scroll: navigationColor.secondary,
  },
  SECONDARY_PRIMARY: {
    base: navigationColor.secondary,
    scroll: navigationColor.primary,
  },
  SECONDARY_WHITE: {
    base: navigationColor.secondary,
    scroll: navigationColor.white,
  },
  SECONDARY_BLACK: {
    base: navigationColor.secondary,
    scroll: navigationColor.black,
  },
  WHITE_WHITE: {
    base: navigationColor.white,
    scroll: navigationColor.white,
  },
  WHITE_PRIMARY: {
    base: navigationColor.white,
    scroll: navigationColor.primary,
  },
  WHITE_SECONDARY: {
    base: navigationColor.white,
    scroll: navigationColor.secondary,
  },
  WHITE_BLACK: {
    base: navigationColor.white,
    scroll: navigationColor.black,
  },
  BLACK_BLACK: {
    base: navigationColor.black,
    scroll: navigationColor.black,
  },
  BLACK_PRIMARY: {
    base: navigationColor.black,
    scroll: navigationColor.primary,
  },
  BLACK_SECONDARY: {
    base: navigationColor.black,
    scroll: navigationColor.secondary,
  },
  BLACK_WHITE: {
    base: navigationColor.black,
    scroll: navigationColor.white,
  },
};

type BackgroundType = typeof navigationBackground[keyof typeof navigationBackground];

type ThemeType = typeof navigationTheme[keyof typeof navigationTheme];

type NavigationBarProps = {
  className: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  theme: ThemeType;
  maxWidth: string;
  background: BackgroundType;
  showProgressBar: boolean;
  distance: number;
  parentRef: React.MutableRefObject<HTMLElement>;
  children: ReactNode;
};

function NavigationBar({
  className,
  isOpen,
  setIsOpen,
  theme = navigationTheme.PRIMARY_SECONDARY,
  maxWidth = '',
  background = navigationBackground.transparent,
  children,
  showProgressBar,
  distance,
  parentRef,
}: NavigationBarProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const component = 'navigation_bar';
  const ref = useRef(null);

  const scrollColor = distance === 1 ? theme.scroll : theme.base;
  const isOpenColor = navigationColor.secondary;

  const { showBanner, message } = useBanner();

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const { navigationHeight } = storageKeys;
    const { offsetHeight } = ref.current;

    sessionStorage.setItem(navigationHeight, offsetHeight);
  }, []);

  const navigateHome = () => {
    const event = t('event.navigation.back_to_home', { component });
    buttonClick(event);

    parentRef?.current?.scroll({ top: 0, behavior: 'smooth' });
    navigate(pageMapper.home);
  };

  return (
    <>
      {showBanner && <Banner label={message} />}

      <div className={cn(maxWidth, background.base, 'hidden w-full h-8 lg:block flex-shrink-0')} />

      <div
        ref={ref}
        className={joinValues({
          base: 'sticky top-0 z-20 flex flex-col w-full',
          lg: 'lg:top-0',
          options: className,
        })}
      >
        <div className="flex justify-center">
          <ComponentLayout
            className={joinValues({
              base: 'justify-between w-full',
              spacing: 'py-6',
              background: background.base,
              color: scrollColor.text,
              border: scrollColor.border,
              maxWidth,
            })}
          >
            <button
              onClick={navigateHome}
              type="button"
              name="navi_logo_button"
              id="navi_logo_button"
              aria-label="navi_logo_button"
            >
              <RabobankLogo
                className={joinValues({
                  base: 'h-6',
                  desktop: 'lg:opacity-100',
                  animation: 'navigation-header',
                  opacity: isOpen ? 'opacity-0' : 'navigation-header-closed opacity-100',
                  color: scrollColor.logo,
                  hover: 'hover:fill-primary',
                  xl: 'xl:fill-secondary',
                })}
              />
            </button>

            <HamburgerButton
              className="z-10 lg:hidden"
              color={isOpen ? isOpenColor.hamburger : scrollColor.hamburger}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />

            {children}
          </ComponentLayout>
        </div>

        {showProgressBar && <ProgressBar className="lg:hidden" distance={distance} />}

        {/* Mobile background */}
        <div
          style={{ opacity: distance }}
          className={joinValues({
            base: 'w-full bg-white -z-20',
            ProgressBar: showProgressBar ? 'bottom-[18px]' : 'bottom-0',
            position: 'absolute -top-8 left-0',
            shadow: !showProgressBar && 'drop-shadow-rabobank',
            lg: 'lg:hidden',
          })}
        />

        {/* Desktop background */}
        <div
          className={joinValues({
            base: 'hidden w-full -z-20',
            opacity: distance === 1 ? 'opacity-100' : 'opacity-0',
            position: 'absolute -top-8 bottom-0 left-0',
            shadow: 'drop-shadow-rabobank',
            animation: 'transition-opacity duration-300',
            background: background.scroll,
            lg: 'lg:block',
          })}
        />
      </div>
    </>
  );
}

export default NavigationBar;
