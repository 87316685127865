import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

type PageTitleProps = {
  title: string;
  description: string;
};

function PageTitle({ title, description }: PageTitleProps) {
  const { t } = useTranslation();

  return (
    <Helmet>
      <title>{t(title)}</title>
      <meta name="description" content={t(description)} />
    </Helmet>
  );
}

export default PageTitle;
