export const requester1 = {
  name: 'Dimensio Verpakkingen B.V.',
  kvkNumber: '33236408',
};

export const requester2 = {
  name: 'Hoveniersbedrijf Nieland B.V.',
  kvkNumber: '02034426',
};

export const requester3 = {
  name: 'Mr Again B.V.',
  kvkNumber: '87746867',
};
