import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../hooks/useIsMobile';
import Modal from '../ui/Modal';
import MailIcon from '../../public/icons/raboIcons/mail.svg';
import PhoneIcon from '../../public/icons/raboIcons/person.svg';
import ButtonCard from './ButtonCard';
import SendMessage from './SendMessage';

function ContactCards() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const [requestMailIsOpen, setRequestMailIsOpen] = useState(false);

  return (
    <>
      <div className="flex flex-col gap-10 w-full lg:flex-row">
        <ButtonCard
          title={t('create_call.title')}
          text={t('create_call.text')}
          Icon={PhoneIcon}
          className="flex-1"
          onClick={() => {
            window.location.href = t('text_65d13d2a75e53400955c2708');
          }}
        />
        <ButtonCard
          title={t('send_message.title')}
          text={t('send_message.text')}
          Icon={MailIcon}
          className="flex-1"
          onClick={() => setRequestMailIsOpen(true)}
        />
      </div>

      <Modal
        isOpen={requestMailIsOpen}
        noFocus={isMobile}
        setIsOpen={setRequestMailIsOpen}
        position="bottom"
        className="max-w-2xl"
      >
        <SendMessage setIsOpen={setRequestMailIsOpen} />
      </Modal>
    </>
  );
}

export default ContactCards;
