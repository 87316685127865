import React from 'react';
import { useTranslation } from 'react-i18next';
import { clearSession } from '../../utils/helpers';
import Button from '../ui/Button';
import Header from '../ui/Header';
import Text from '../ui/Text';

type SessionExpiredProps = {
  setIsOpen: (value: boolean) => void;
};

function SessionExpired({ setIsOpen }: SessionExpiredProps) {
  const { t } = useTranslation();
  const component = 'session_expired';

  const onClick = () => {
    setIsOpen(false);
    clearSession();
    window.location.replace('/');
  };

  return (
    <div className="flex flex-col items-center gap-4 p-4 text-center">
      <Header
        langKey="text_65c16820b86ba700b5c206e6"
        element="h1"
      />
      <Text
        langKey="text_65c16820b86ba700b5c206e7"
        size="sm"
      />
      <Button
        label={t('text_65c16820b86ba700b5c206e8')}
        name={`${component}_start_new`}
        className="mt-2 w-fit"
        color="primary"
        onClick={onClick}
      />
    </div>
  );
}

export default SessionExpired;
