import React from 'react';
import { useTranslation } from 'react-i18next';
import { buttonClick } from '../../utils/analytics';
import ModalLayout from '../../components/layout/ModalLayout';
import Button from '../../components/ui/Button';
import Header from '../../components/ui/Header';
import Text from '../../components/ui/Text';
import Confetti from '../../public/icons/confetti.svg';

const component = 'reconsent_success';

type ReconsentSuccessProps = {
  setIsOpen: (value: boolean) => void;
};

function ReconsentSuccess({ setIsOpen }: ReconsentSuccessProps) {
  const { t } = useTranslation();

  const onClose = () => {
    setIsOpen(false);

    const event = t('event.close', { component });
    buttonClick(event);
  };

  return (
    <ModalLayout>
      <ModalLayout.CloseButton
        onClose={onClose}
        name={`${component}_close`}
      />

      <div className="flex flex-col gap-4 items-center lg:gap-8">
        <Confetti />

        <div className="flex flex-col gap-4 items-center max-w-xs lg:gap-2">
          <Header
            langKey="text_65b9af88774e154fb120cf96"
            element="h1"
          />
          <Text
            langKey="text_65b9af88774e154fb120cf97"
            className="text-center"
          />
        </div>

        <Button
          label={t('text_65b9af88774e154fb120cf98')}
          name="reconsent_success_close"
          color="primary"
          onClick={() => setIsOpen(false)}
        />
      </div>
    </ModalLayout>
  );
}

export default ReconsentSuccess;
