import { useEffect, useState } from 'react';

const useScrollDistance = ({ range, offset = 0, reverse = false, container }) => {
  const [distance, setDistance] = useState(reverse ? 1 : 0);

  useEffect(() => {
    const updateDistance = () => {
      const { scrollTop } = container?.current ?? 0;

      const calc = Math.min(Math.max((scrollTop - offset) / range, 0), 1);
      const result = reverse ? 1 - calc : calc;

      setDistance(result);
    };

    updateDistance();

    container?.current?.addEventListener('scroll', updateDistance);

    return () => {
      container?.current?.removeEventListener('scroll', updateDistance);
    };
  }, []);

  return distance;
};

export default useScrollDistance;
