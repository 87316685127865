/* eslint-disable no-console */
import i18n from '../i18n';
import { storageKeys } from './constants.ts';
import { isBrowser } from './helpers.ts';

let isTester;
let isFirstVisit;

if (isBrowser) {
  isFirstVisit = !localStorage.getItem(storageKeys.hasVisited);
}

if (isFirstVisit && isBrowser) {
  localStorage.setItem(storageKeys.hasVisited, 'true');
}

const isDebug = process.env.NODE_ENV === 'development';

const updateAnalyticSettings = async (value) => {
  if (!value) {
    return;
  }

  console.log(await i18n.t('error.analytics.disabled'));
  isTester = value;
};

const measure = (detail) => {
  if (isDebug) {
    console.log('Analytics:', { ...detail });
  }

  if (isTester) {
    return;
  }

  const event = new CustomEvent('sendDataView', { detail });
  document.dispatchEvent(event);
};

const pageView = (url) => measure({
  page_name: url,
});

const tagComponent = (label, type, value) => measure({
  event_action: `${type} click`,
  event_category: type,
  event_label: label,
  event_value: value,
});

const tagCheckbox = (label, value) => measure({
  event_action: `checkbox ${value ? 'checked' : 'unchecked'}`,
  event_category: 'checkbox',
  event_label: label,
});

const tagLink = (label, url) => measure({
  event_action: 'link click',
  event_category: 'link',
  event_label: `${label} - ${url}`,
});

const buttonClick = (event, value) => {
  const { textContent } = event.target ?? {};
  const eventName = textContent ?? event;

  if (!eventName) {
    return;
  }

  tagComponent(eventName, 'button', value);
};

const checkboxClick = (text, event) => {
  const { checked } = event.target ?? {};

  if (!text) {
    return;
  }

  tagCheckbox(text, checked);
};

const linkClick = (event) => {
  const { textContent, href } = event.target ?? {};

  if (!textContent || !href) {
    return;
  }

  tagLink(textContent, href);
};

const dropdownSelect = (event) => {
  const text = event?.title;

  if (!text) {
    return;
  }

  tagComponent(text, 'dropdown');
};

const videoMeasure = (label, category, action) => {
  measure({
    event_label: label,
    event_category: category,
    event_action: action,
    event_value: `${label} - ${category} - ${action}`,
  });
};

const getSubstepName = (name, treatment) => {
  const treatmentName = treatment ? `- ${treatment} -` : '-';
  const preFixVisitor = isFirstVisit ? 'new' : 'existing';

  return `${name} ${treatmentName} ${preFixVisitor} visitor`;
};

const getStepNumber = (stepNumber, treatment) => (treatment === 'on' ? `${stepNumber + 1}` : String(stepNumber));

const getStepType = (name) => {
  if (name.includes('Your application')) {
    return '1';
  }

  if (name.includes('Next steps')) {
    return '4';
  }

  return '0';
};

// TODO: measuring application attributes doesn't work at the moment.
// The team responsible for fixing this is working on it.
// Their progress can be tracked here:
// https://dev.azure.com/raboweb/DCP/_workitems/edit/8334138
// If a fix takes too long, we should consider removing the application attributes.
const measurePage = (step, name, treatment) => measure({
  application_stepname: name,
  application_stepnumber: step,
  application_process: 'stp',
  application_id: 'pbl_pbc',
  application_segment: 'business',
  application_substepnumber: step,
  application_substepname: getSubstepName(name, treatment),
  application_steptype: getStepType(name),
  application_attribute_1: name,
});

const measureHomepage = (treatment) => {
  const name = 'Homepage';
  const step = '1';

  measurePage(step, name, treatment);
};

const measureBusinessLoan = (treatment) => {
  const name = 'Business loan';
  const step = '1';

  measurePage(step, name, treatment);
};

const measureBusinessCredit = (treatment) => {
  const name = 'Business credit';
  const step = '1';

  measurePage(step, name, treatment);
};

const measureStartYourApplication = (treatment) => {
  const name = 'Start your application';
  const step = '2';

  measurePage(step, name, treatment);
};

const measureYourApplication = (treatment, value) => {
  const name = `Your application - ${value ?? 'Unknown'}`;
  const step = getStepNumber(2, treatment);

  measurePage(step, name, treatment);
};

const measureExtraExpenses = (treatment) => {
  const name = 'Extra expenses';
  const step = getStepNumber(3, treatment);

  measurePage(step, name, treatment);
};

const measureTransactionConsent = (treatment) => {
  const name = 'Transaction consent';
  const step = getStepNumber(4, treatment);

  measurePage(step, name, treatment);
};

const measureTransactionsAddAccount = (treatment) => {
  const name = 'Transactions add account';
  const step = getStepNumber(5, treatment);

  measurePage(step, name, treatment);
};

const measureRedirection = (treatment) => {
  const name = 'Redirection';
  const step = getStepNumber(6, treatment);

  measurePage(step, name, treatment);
};

const measureTransactionSuccess = (treatment) => {
  const name = 'Transaction success';
  const step = getStepNumber(7, treatment);

  measurePage(step, name, treatment);
};

const measureCollectTransactions = (treatment) => {
  const name = 'Collect transactions';
  const step = getStepNumber(8, treatment);

  measurePage(step, name, treatment);
};

const measureTransactionsOverview = (treatment) => {
  const name = 'Transactions overview';
  const step = getStepNumber(9, treatment);

  measurePage(step, name, treatment);
};

const measureGetOffers = (treatment) => {
  const name = 'Get offers';
  const step = getStepNumber(10, treatment);

  measurePage(step, name, treatment);
};

const measureOfferOptions = (treatment) => {
  const name = 'Offer options';
  const step = getStepNumber(11, treatment);

  measurePage(step, name, treatment);
};

const measureOfferOverview = (treatment, value) => {
  const name = `Offer overview - ${value ?? 'Unknown'}`;
  const step = getStepNumber(12, treatment);

  measurePage(step, name, treatment);
};

const measureNextSteps = (treatment, value) => {
  const name = `Next steps - ${value ?? 'Unknown'}`;
  const step = getStepNumber(13, treatment);

  measurePage(step, name, treatment);
};

const measureFeedbackSuccess = (treatment) => {
  const name = 'Feedback success';
  const step = getStepNumber(14, treatment);

  measurePage(step, name, treatment);
};

// LandingPages
const measureArticleDebtors = (treatment) => {
  const name = 'content_debtors';
  const step = '0';
  measurePage(step, name, treatment);
};

const measureArticleDebtorsBnr = (treatment) => {
  const name = 'content_debtorsBnr';
  const step = '0';
  measurePage(step, name, treatment);
};

const measureArticleWorkingCapital1 = (treatment) => {
  const name = 'content_workingcapital1';
  const step = '0';
  measurePage(step, name, treatment);
};

const measureArticleSmeFinancing = (treatment) => {
  const name = 'content_smefinancing';
  const step = '0';
  measurePage(step, name, treatment);
};

const measureArticleInventoryFinancing = (treatment) => {
  const name = 'content_inventoryfinancing';
  const step = '0';
  measurePage(step, name, treatment);
};

const measureArticleImproveLiquidity = (treatment) => {
  const name = 'content_liquidity';
  const step = '0';
  measurePage(step, name, treatment);
};

const measureArticleInventoryManagement = (treatment) => {
  const name = 'content_inventorymanagement';
  const step = '0';
  measurePage(step, name, treatment);
};

const measureArticleVat = (treatment) => {
  const name = 'content_vat';
  const step = '0';
  measurePage(step, name, treatment);
};

const measureArticleEcommerce = (treatment) => {
  const name = 'content_ecommerce';
  const step = '0';
  measurePage(step, name, treatment);
};

const measureArticleWorkingCapital2 = (treatment) => {
  const name = 'content_workingcapital2';
  const step = '0';
  measurePage(step, name, treatment);
};

const measureArticleOverdraft = (treatment) => {
  const name = 'content_overdraft';
  const step = '0';
  measurePage(step, name, treatment);
};

const measureArticleSuccesfullRequest = (treatment) => {
  const name = 'content_succesvolleaanvraag';
  const step = '0';
  measurePage(step, name, treatment);
};

const measureArticleCustomerRelation = (treatment) => {
  const name = 'content_gezondeklantrelatie';
  const step = '0';
  measurePage(step, name, treatment);
};

const measureArticleUnforeseenCosts = (treatment) => {
  const name = 'content_onvoorzienekosten';
  const step = '0';
  measurePage(step, name, treatment);
};

const measureArticleGenerationDifferences = (treatment) => {
  const name = 'content_generatieverschillen';
  const step = '0';
  measurePage(step, name, treatment);
};

const measureArticleNegativeBKR = (treatment) => {
  const name = 'content_negatievebkr';
  const step = '0';
  measurePage(step, name, treatment);
};

// The remaining functions are not part of the funnel.
// They are either sidebranches from the funnel (FAQ),
// new funnels altogether (Get reconsent),
// or error pages (Request failed).
const measureFaq = (treatment) => {
  const name = 'FAQ';
  const step = '0';

  measurePage(step, name, treatment);
};

const measureContact = (treatment) => {
  const name = 'Contact';
  const step = '0';

  measurePage(step, name, treatment);
};

const measureGetReconsent = (treatment) => {
  const name = 'Get reconsent';
  const step = '0';

  measurePage(step, name, treatment);
};

const measureReconsentOverview = (treatment) => {
  const name = 'Reconsent overview';
  const step = '0';

  measurePage(step, name, treatment);
};

const measureReconsentVerification = (treatment) => {
  const name = 'Reconsent verification';
  const step = '0';

  measurePage(step, name, treatment);
};

const measureRequestFailed = (treatment, value) => {
  const name = `Request failed - ${value ?? 'Unknown'}`;
  const step = '0';

  measurePage(step, name, treatment);
};

const measureEligibilityModal = (treatment) => {
  const name = 'Eligibility modal';
  const step = '0';

  measurePage(step, name, treatment);
};

// The functions below do not measure page views.
// They are used to measure specific events.
const measureVideo = (treatment, value) => {
  const name = `Video - ${value ?? 'Unknown'}`;
  const step = '0';

  measurePage(step, name, treatment);
};

const measureLinkClick = (value) => {
  const name = `Link click - ${value ?? 'Unknown'}`;
  const step = '0';

  measurePage(step, name);
};

export {
  updateAnalyticSettings,
  pageView,
  buttonClick,
  checkboxClick,
  linkClick,
  dropdownSelect,
  videoMeasure,
  measureHomepage,
  measureBusinessLoan,
  measureBusinessCredit,
  measureStartYourApplication,
  measureYourApplication,
  measureExtraExpenses,
  measureTransactionConsent,
  measureTransactionsAddAccount,
  measureRedirection,
  measureTransactionSuccess,
  measureCollectTransactions,
  measureTransactionsOverview,
  measureGetOffers,
  measureOfferOptions,
  measureOfferOverview,
  measureNextSteps,
  measureFeedbackSuccess,
  measureFaq,
  measureContact,
  measureGetReconsent,
  measureReconsentOverview,
  measureReconsentVerification,
  measureRequestFailed,
  measureEligibilityModal,
  measureVideo,
  measureLinkClick,
  measureArticleDebtors,
  measureArticleDebtorsBnr,
  measureArticleWorkingCapital1,
  measureArticleSmeFinancing,
  measureArticleInventoryFinancing,
  measureArticleImproveLiquidity,
  measureArticleInventoryManagement,
  measureArticleVat,
  measureArticleEcommerce,
  measureArticleWorkingCapital2,
  measureArticleOverdraft,
  measureArticleSuccesfullRequest,
  measureArticleCustomerRelation,
  measureArticleUnforeseenCosts,
  measureArticleGenerationDifferences,
  measureArticleNegativeBKR,
};
