import type { GetTransactionsResponse } from '../../services/pbl-api';

export const status = {
  iban: 'NL00RABO0000000001',
  psd2StatusV1: 'STORAGE',
  username: '1b28b6e2-9d01-4b35-9c14-1525155b1045',
  bank: 'RABO',
  trackingId: '1b28b6e2-9d01-4b35-9c14-1525155b1045',
};

export const customerId = '926108919934229314';

export const transactionInfo: GetTransactionsResponse = {
  accounts: [
    {
      clientName: 'Rabobank Nederland B.V.',
      iban: 'NL80RABO1127000002',
      matchState: 'MATCH',
    },
    {
      clientName: 'Rabobank Nederland B.V.',
      iban: 'NL80RABO1127000003',
      matchState: 'COULD_NOT_MATCH',
    },
    {
      clientName: 'Rabobank Nederland B.V.',
      iban: 'NL80RABO1127000004',
      matchState: 'NO_MATCH',
    },
  ],
  bank: 'RABOBANK',
  sub: '1b28b6e2-9d01-4b35-9c14-1525155b1045',
};

export const connectionId: string = '983437195480144072';
