import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { getJwtToken } from '../services/authentication.ts';
import { getCaseState } from '../services/pbl-api.ts';
import { pageMapper, storageKeys } from '../utils/constants.ts';
import { getQueryParams, getStatusError, setCorrelationId } from '../utils/helpers.ts';
import { useStoreContext } from '../utils/store.tsx';

function useAuth(authenticate = true) {
  const { actions } = useStoreContext();
  const { resetState } = actions;

  const [isFinished, setIsFinished] = useState(false);
  const [error, setError] = useState();

  const { t } = useTranslation();
  const location = useLocation();

  const pathToSkipAuth = [
    pageMapper.reconsent,
  ];

  const getPartnerId = () => {
    const queryParams = getQueryParams();

    return Object.keys(queryParams).reduce((res, cur) => {
      const key = cur.toLowerCase();

      return key === 'id'
        ? queryParams[cur].trim().toLowerCase()
        : res;
    }, '');
  };

  const setPartnerId = (origin) => {
    const activePartnerId = sessionStorage.getItem(storageKeys.partnerId);

    if (origin == null) {
      return;
    }

    const partnerId = origin
      .trim()
      .toLowerCase();

    if (!activePartnerId) {
      sessionStorage.setItem(storageKeys.partnerId, partnerId);
    }
  };

  useEffect(() => {
    if (!authenticate) {
      return;
    }

    const auth = sessionStorage.getItem(storageKeys.jwt);
    const partnerId = getPartnerId();

    const timestamp = Date.now();
    const { exp } = auth ? jwtDecode(auth) : {};

    if (auth && timestamp < (exp * 1000)) {
      setIsFinished(true);
      return;
    }

    const { pathname } = location;
    const shouldSkip = pathToSkipAuth.some((path) => pathname.includes(path));

    if (shouldSkip) {
      setIsFinished(true);
      return;
    }

    // Make sure old token is cleared to get a new token
    sessionStorage.removeItem(storageKeys.jwt);

    // Make sure initial state is restored
    resetState();

    const setJwtToken = async () => {
      try {
        const tokenRes = await getJwtToken({ partnerId });
        const { token, caseId } = tokenRes.data;

        if (['null', 'undefined'].includes(token) || !token) {
          // TODO: set custom error
          return;
        }

        const jwt = jwtDecode(token);

        sessionStorage.setItem(storageKeys.caseId, caseId);
        sessionStorage.setItem(storageKeys.jwt, token);

        setIsFinished(true);

        // eslint-disable-next-line no-console
        console.log(`User identification: [${jwt.sub}/${caseId}]`);

        const { data } = await getCaseState();
        const { origin } = data ?? {};

        setPartnerId(origin);
        setCorrelationId(tokenRes);
      } catch (err) {
        const errorKey = getStatusError(err);

        setError(t(`error.${errorKey}`));
      }
    };

    setJwtToken();
  }, [authenticate]);

  return { isFinished, error };
}

export default useAuth;
