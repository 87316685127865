import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { pageMapper } from '../utils/constants';
import AppContainer from '../pages/AppContainer';
import Contact from '../pages/Contact';
import Faq from '../pages/Faq';
import GetReconsent from '../pages/GetReconsent';
import Reconsent from '../pages/Reconsent';

const Home = lazy(() => import('../pages/Home'));
const KortlopendeLening = lazy(() => import('../pages/KortlopendeLening'));
const KortlopendKrediet = lazy(() => import('../pages/KortlopendKrediet'));
const NotFound = lazy(() => import('../pages/NotFound'));
const OverJou = lazy(() => import('../pages/OverJou'));
const ExtraUitgaven = lazy(() => import('../pages/ExtraUitgaven'));
const RequestFailed = lazy(() => import('../pages/RequestFailed'));
const Transacties = lazy(() => import('../pages/Transacties'));
const TransactiesVerzamelen = lazy(() => import('../pages/TransactiesVerzamelen'));
const Offer = lazy(() => import('../pages/Offer'));
const GetOffers = lazy(() => import('../pages/GetOffers'));
const OfferSuccess = lazy(() => import('../pages/OfferSuccess'));
const FeedbackSuccess = lazy(() => import('../pages/FeedbackSuccess'));
const TransactionSuccess = lazy(() => import('../pages/TransactionSuccess'));
const Redirection = lazy(() => import('../pages/Redirection'));

// Landingpages
const Debiteuren = lazy(() => import('../pages/Kennisbank/Ondernemen/DebiteurenBeheer'));
const DebiteurenBnr = lazy(() => import('../pages/Kennisbank/Ondernemen/DebiteurenBeheerBnr'));
const Werkkapitaal = lazy(() => import('../pages/Kennisbank/FinancieleBegrippen/Werkkapitaal'));
const MkbFinanciering = lazy(() => import('../pages/Kennisbank/Financieringsmogelijkheden/MbkFinanciering'));
const VoorraadFinancieren = lazy(() => import('../pages/Kennisbank/Financieringsmogelijkheden/VoorraadFinancieren'));
const LiquiditeitVerbeteren = lazy(() => import('../pages/Kennisbank/Financieringsmogelijkheden/LiguiditeitVerbeteren'));
const VoorraadBeheer = lazy(() => import('../pages/Kennisbank/Ondernemen/VoorraadBeheer'));
const Btw = lazy(() => import('../pages/Kennisbank/FinancieleBegrippen/Btw'));
const Ecommerce = lazy(() => import('../pages/Kennisbank/Ondernemen/Ecommerce'));
const WerkkapitaalFinanciering = lazy(() => import('../pages/Kennisbank/Financieringsmogelijkheden/Werkkapitaalfinanciering'));
const Roodstaan = lazy(() => import('../pages/Kennisbank/FinancieleBegrippen/RoodStaan'));
const SuccesvolleAanvraag = lazy(() => import('../pages/Kennisbank/Financieringsmogelijkheden/SuccesvolleAanvraag'));
const GezondeKlantrelatie = lazy(() => import('../pages/Kennisbank/Ondernemen/GezondeKlantrelatie'));
const OnvoorzieneKosten = lazy(() => import('../pages/Kennisbank/Ondernemen/OnvoorzieneKosten'));
const GeneratieVerschillen = lazy(() => import('../pages/Kennisbank/Ondernemen/GeneratieVerschillen'));
const NegativeBKR = lazy(() => import('../pages/Kennisbank/Ondernemen/NegativeBKR'));

export default function Pages() {
  return (
    <Routes>
      <Route path={pageMapper.home} element={<AppContainer />}>
        <Route index element={<Home />} />
        <Route path={`${pageMapper.zakelijkeLening}`} element={<KortlopendeLening />} />
        <Route path={`${pageMapper.kortlopendeLening}`} element={<KortlopendeLening />} />
        <Route path={`${pageMapper.zakelijkKrediet}`} element={<KortlopendKrediet />} />
        <Route path={`${pageMapper.kortlopendKrediet}`} element={<KortlopendKrediet />} />
        <Route path={`${pageMapper.contact}`} element={<Contact />} />
        <Route path={`${pageMapper.faq}`} element={<Faq />} />
        <Route path={`${pageMapper.aboutYou}`} element={<OverJou />} />
        <Route path={`${pageMapper.extraExpenses}`} element={<ExtraUitgaven />} />
        <Route path={`${pageMapper.transactions}`} element={<Transacties />} />
        <Route path={`${pageMapper.getTransactions}`} element={<TransactiesVerzamelen />} />
        <Route path={`${pageMapper.requestFailed}`} element={<RequestFailed />} />
        <Route path={`${pageMapper.getOffers}`} element={<GetOffers />} />
        <Route path={`${pageMapper.offer}`} element={<Offer />} />
        <Route path={`${pageMapper.offerSuccess}`} element={<OfferSuccess />} />
        <Route path={`${pageMapper.feedbackSuccess}`} element={<FeedbackSuccess />} />
        <Route path={`${pageMapper.reconsent}`} element={<Reconsent />} />
        <Route path={`${pageMapper.getReconsent}`} element={<GetReconsent />} />

        <Route path={`${pageMapper.kennisbankOndernemenDebiteuren}`} element={<Debiteuren />} />
        <Route path={`${pageMapper.kennisbankOndernemenDebiteurenBnr}`} element={<DebiteurenBnr />} />
        <Route path={`${pageMapper.kennisbankFinancieleBegrippenWerkkapitaal}`} element={<Werkkapitaal />} />
        <Route path={`${pageMapper.kennisbankFinancieringsmogelijkhedenMkbFinanciering}`} element={<MkbFinanciering />} />
        <Route path={`${pageMapper.kennisbankFinancieringsmogelijkhedenVoorraadFinancieren}`} element={<VoorraadFinancieren />} />
        <Route path={`${pageMapper.kennisbankFinancieleBegrippenLiquiditeitVerbeteren}`} element={<LiquiditeitVerbeteren />} />
        <Route path={`${pageMapper.kennisbankOndernemenVoorraadBeheer}`} element={<VoorraadBeheer />} />
        <Route path={`${pageMapper.kennisbankFinancieleBegrippenWatIsBtw}`} element={<Btw />} />
        <Route path={`${pageMapper.kennisbankOndernemenEcommerce}`} element={<Ecommerce />} />
        <Route path={`${pageMapper.kennisbankFinancieringsmogelijkhedenWerkkapitaalfinanciering}`} element={<WerkkapitaalFinanciering />} />
        <Route path={`${pageMapper.kennisbankFinancieleBegrippenRoodStaan}`} element={<Roodstaan />} />
        <Route path={`${pageMapper.kennisbankFinancieringsmogelijkhedenKansOpSuccesvolleAanvraag}`} element={<SuccesvolleAanvraag />} />
        <Route path={`${pageMapper.kennisbankOndernemenGezondeKlantrelatie}`} element={<GezondeKlantrelatie />} />
        <Route path={`${pageMapper.kennisbankOndernemenOnvoorzienekosten}`} element={<OnvoorzieneKosten />} />
        <Route path={`${pageMapper.kennisbankOndernemenGeneratieVerschillen}`} element={<GeneratieVerschillen />} />
        <Route path={`${pageMapper.kennisbankOndernemenNegativeBKR}`} element={<NegativeBKR />} />

        <Route path="*" element={<NotFound />} />
      </Route>

      <Route path={`${pageMapper.redirection}`} element={<Redirection />} />
      <Route path={`${pageMapper.transactionSuccess}`} element={<TransactionSuccess />} />
    </Routes>
  );
}
