import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import usePageMeasure from '../../hooks/usePageMeasure';
import { pageMapper } from '../../utils/constants';
import ContactCards from '../../components/container/ContactCards';
import Footer from '../../components/container/Footer';
import ComponentLayout from '../../components/layout/ComponentLayout';
import Button from '../../components/ui/Button';
import Header from '../../components/ui/Header';
import PageTitle from '../../components/ui/PageTitle';
import Text from '../../components/ui/Text';
import RabobankInfo from './RabobankInfo';
import WordPartner from './WordPartner';

function Contact() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  usePageMeasure('contact');
  const page = 'contact';

  return (
    <>
      <PageTitle title="text_65b818c13045ed00dcdb9e26" description="text_65b818c13045ed00dcdb9e28" />

      <ComponentLayout className="flex flex-col items-center py-10 gap-10 lg:pt-20 lg:gap-20">
        <div className="flex flex-col items-center gap-6 max-w-xl lg:gap-10">
          <div className="flex flex-col gap-2 text-center lg:gap-0">
            <Header
              langKey="text_65b98fc42cb7b100f5ccf210"
              size="h2"
              element="h1"
            />
            <Text
              langKey="text_65b98fc42cb7b100f5ccf211"
            />
          </div>

          <Button
            name={`${page}_open_modal`}
            label={t('text_65b98fc42cb7b100f5ccf212')}
            className="w-full sm:w-fit"
            color="secondary"
            variant="outline"
            onClick={() => navigate(pageMapper.faq)}
          />
        </div>

        <ContactCards />
      </ComponentLayout>

      <WordPartner />
      <RabobankInfo />
      <Footer />
    </>
  );
}

export default Contact;
