import React, { useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isAxiosError } from 'axios';
import { LayoutGroup, motion } from 'framer-motion';
import jwtDecode, { type JwtPayload } from 'jwt-decode';
import { getResumeToken } from '../../services/authentication';
import useHeight from '../../hooks/useHeight';
import usePageMeasure from '../../hooks/usePageMeasure';
import AuthError from '../../utils/AuthError';
import { storageKeys } from '../../utils/constants';
import { unknownError } from '../../utils/errors';
import { getQueryParams, getStatusError, transition } from '../../utils/helpers';
import { useStoreContext } from '../../utils/store';
import NeedHelp from '../../components/container/NeedHelp';
import Questions from '../../components/container/Questions';
import SubmitButton from '../../components/container/SubmitButton';
import PageLayout from '../../components/layout/PageLayout';
import StickyButtonLayout from '../../components/layout/StickyButtonLayout';
import Divider from '../../components/ui/Divider';
import Header from '../../components/ui/Header';
import Input from '../../components/ui/Input';
import Text from '../../components/ui/Text';
import { type ReconsentView } from '.';

const formName = 'verification_form';

type FormValue = {
  kvk_input: string;
};

const page = 'verification';

const faqItems = [
  {
    header: 'faq.item7.question',
    text: 'faq.item7.answer',
  },
  {
    header: 'faq.item12.question',
    text: 'faq.item12.answer',
  },
  {
    header: 'faq.item8.question',
    text: 'faq.item8.answer',
  },
  {
    header: 'faq.item6.question',
    text: 'faq.item6.answer',
  },
];

type VerificationProps = {
  setView: (value: ReconsentView) => void;
};

function Verification({ setView }: VerificationProps) {
  const [verificationError, setVerificationError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const { actions } = useStoreContext();
  const { setRequester } = actions;

  const { t } = useTranslation();
  const [buttonHeightRef, buttonHeight] = useHeight();

  usePageMeasure('reconsentVerification');

  const {
    handleSubmit,
    register,
    formState,
  } = useForm<FormValue>({ mode: 'onBlur' });

  const { errors, isValid } = formState;

  const buttonLabel = t('text_65b9af88774e154fb120cf76');

  const queryParams = getQueryParams();
  const { hash, caseId } = queryParams;

  const onSubmit: SubmitHandler<FormValue> = async (formData) => {
    setIsLoading(true);
    setVerificationError(undefined);

    const kvkNumber = formData.kvk_input;

    if (!hash) {
      setVerificationError('magic_link');
      setIsLoading(false);

      return;
    }

    if (!caseId) {
      throw new AuthError('caseIdNotFound');
    }

    try {
      const { data } = await getResumeToken({ caseId, kvkNumber, hash });
      const { token } = data;

      const jwt = jwtDecode<JwtPayload>(token);

      sessionStorage.setItem(storageKeys.caseId, caseId);
      sessionStorage.setItem(storageKeys.jwt, token);

      // eslint-disable-next-line no-console
      console.log(`User identification: [${jwt.sub}/${caseId}]`);
      setRequester({ kvkNumber });

      setView('transaction_overview');
    } catch (err) {
      if (!isAxiosError(err)) {
        setVerificationError(unknownError);
        return;
      }

      const status = err?.response?.status;

      if (status === 401) {
        setVerificationError('reconsent_kvk');
        return;
      }

      const errorKey = getStatusError(err);
      setVerificationError(errorKey);
    } finally {
      setIsLoading(false);
    }
  };

  const inputLabel = (
    <Text langKey="form.kvk_number.label" size="sm" />
  );

  return (
    <LayoutGroup id={page}>
      <PageLayout>
        <PageLayout.MainColumn>
          <div className="flex flex-col gap-4 pb-8 lg:gap-8 lg:pb-0">

            <motion.div layout="position" className="flex flex-col gap-4 lg:gap-2">
              <Header
                langKey="text_65b9af88774e154fb120cf69"
                element="h1"
              />
              <Text
                langKey="text_65b9af88774e154fb120cf6a"
              />
            </motion.div>

            <form
              className="flex flex-col gap-2"
              onSubmit={handleSubmit(onSubmit)}
              id={formName}
            >
              <motion.div layoutId="company" transition={transition}>
                <Input
                  placeholder={t('text_65b9af88774e154fb120cf6c')}
                  label={inputLabel}
                  className="w-full"
                  error={errors.kvk_input?.message}
                  {...register('kvk_input', {
                    required: t('error.required'),
                  })}
                />
              </motion.div>
            </form>

            <Divider
              className="hidden lg:flex"
              color="dark"
            />

            <SubmitButton
              className="hidden ml-auto lg:flex"
              error={verificationError}
              isLoading={isLoading}
              disabled={!isValid}
              name={`${formName}_lg`}
              form={formName}
              label={buttonLabel}
            />

          </div>
        </PageLayout.MainColumn>

        <PageLayout.SideColumn>
          <Questions
            header="text_65b9af88774e154fb120cf6d"
            items={faqItems}
            bottomOffset={buttonHeight}
          />
          <NeedHelp />
        </PageLayout.SideColumn>

        <StickyButtonLayout
          ref={buttonHeightRef}
        >
          <SubmitButton
            className="w-full sm:max-w-xs"
            error={verificationError}
            disabled={!isValid}
            isLoading={isLoading}
            name={`${formName}_sm`}
            form={formName}
            label={buttonLabel}
          />
        </StickyButtonLayout>
      </PageLayout>
    </LayoutGroup>
  );
}

export default Verification;
