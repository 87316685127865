import React from 'react';
import ComponentLayout from '../../components/layout/ComponentLayout';
import Header from '../../components/ui/Header';
import Text from '../../components/ui/Text';

function RabobankInfo() {
  return (
    <ComponentLayout className="flex-col pt-10 gap-2 lg:py-10">
      <div className="flex flex-col max-w-sm">
        <Header
          langKey="text_65b98fc42cb7b100f5ccf21b"
          element="h4"
        />
        <Text
          langKey="text_65b98fc42cb7b100f5ccf21c"
        />
      </div>
    </ComponentLayout>
  );
}

export default RabobankInfo;
