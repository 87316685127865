import React, { type MouseEvent, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { linkClick } from '../../utils/analytics';
import { cn } from '../../utils/helpers';

const variants = {
  base: 'no-underline',
  underline: 'underline',
};

export type Variants = keyof typeof variants;

type LinkProps = {
  href: string;
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  variant?: Variants;
  isExternal?: boolean;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
};

function Link({
  className,
  href,
  onClick,
  children,
  disabled,
  variant = 'base',
  isExternal,
}: LinkProps) {
  const isExternalUrl = isExternal ?? href.includes('http');
  const onClickWithAnalytics = (event: MouseEvent<HTMLAnchorElement>) => {
    linkClick(event);

    if (!onClick) {
      return;
    }

    onClick(event);
  };

  const options = cn(
    disabled && 'pointer-events-none',
    'hover:text-primary',
    variants[variant],
    className,
  );

  const InternalLink = useCallback(() => (
    <RouterLink
      onClick={onClickWithAnalytics}
      to={href}
      className={options}
    >
      {children}
    </RouterLink>
  ), [className, disabled]);

  const ExternalLink = useCallback(() => (
    <a
      onClick={onClickWithAnalytics}
      href={href}
      rel="noopener noreferrer"
      target="_blank"
      className={options}
    >
      {children}
    </a>
  ), [className, disabled]);

  return isExternalUrl
    ? <ExternalLink />
    : <InternalLink />;
}

export default Link;
