import React, {
  type ChangeEvent,
  type FC,
  type KeyboardEvent,
  type MouseEvent,
  type ReactNode,
  type SVGAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';
import { type FieldValues, useController, type UseControllerProps } from 'react-hook-form';
import { CheckIcon } from '@heroicons/react/24/outline';
import { AnimatePresence, motion } from 'framer-motion';
import useOutsideClick from '../../hooks/useOutsideClick';
import { dropdownSelect } from '../../utils/analytics';
import { cn, joinValues, transition } from '../../utils/helpers';
import ChevronIcon from './Chevron';
import { type BaseInputProps, InputContainer, type InputContainerProps } from './Input';
import Loader from './Loader';
import Text from './Text';

export function isOptionDetail<T extends OptionDetail>(option: unknown): option is T {
  if (!option) {
    return false;
  }

  return typeof option === 'object' && 'id' in option && 'title' in option;
}

const sizes = {
  sm: 'max-h-40',
  md: 'max-h-60',
  lg: 'max-h-80',
};

export type OptionDetail = {
  description?: string | string[];
  inactive?: boolean;
  title: string;
  icon?: FC<SVGAttributes<SVGElement>>;
  id: string | number;
};

export type HandleChange<T> = (option: T | ChangeEvent<HTMLElement>) => void;

export type OptionDisplay<T> = (option: T) => string;

export type InputOptionViewProps<T extends OptionDetail, K extends FieldValues> =
  & Omit<InputContainerProps, 'children' | 'name'>
  & BaseInputProps
  & UseControllerProps<K>
  & {
    options: T[];
    isMultiSelect?: boolean;
    displayValue: OptionDisplay<T>;
    size?: keyof typeof sizes;
    shouldOpen?: boolean;
    handleChange?: HandleChange<T>;
    isLoading?: boolean;
    endAdornment?: ReactNode;
  };

function InputOptionView<T extends OptionDetail, K extends FieldValues>({
  options,
  isMultiSelect = false,
  displayValue,
  size = 'lg',
  shouldOpen = true,
  handleChange,
  isLoading,
  endAdornment,
  control,
  rules,
  defaultValue,
  readOnly = false,
  autofill,
  placeholder,
  onFocus,
  error,
  variant,
  name,
  className,
  label,
  disabled,
}: InputOptionViewProps<T, K>) {
  const { field } = useController<K>({ control, name, rules, defaultValue });
  const { onChange, onBlur, value } = field;

  const [activeOption, setActiveOption] = useState<T>();
  const [isOpen, setIsOpen] = useState(false);

  const inputsRef = useRef<HTMLInputElement>(null);
  const optionRef = useRef<HTMLUListElement>(null);

  const ref = useOutsideClick(setActiveOption);
  const noOptions = options.some((option) => option.id === 'empty');

  const hasError = Boolean(error);
  const getValue = (item: T): string | undefined => (item ? displayValue(item) : undefined);

  const sortBySelected = (a: T, b: T) => {
    const valueA = getValue(a);
    const valueB = getValue(b);

    const indexA = value?.find((item: T) => getValue(item) === valueA);
    const indexB = value?.find((item: T) => getValue(item) === valueB);

    if (!valueA || !valueB) {
      return 0;
    }

    if (indexA && indexB) {
      return valueA.localeCompare(valueB);
    }

    if (indexA) {
      return -1;
    }

    if (indexB) {
      return 1;
    }

    return valueA.localeCompare(valueB);
  };

  const inputValue = isMultiSelect
    ? value?.map((item: T) => getValue(item)).join(', ')
    : getValue(value) ?? value;

  const onSelectWithAnalytics = (event: T | ChangeEvent<HTMLElement>) => {
    const isSelection = isOptionDetail(event) && getValue(event);

    if (isOpen && isSelection) {
      dropdownSelect(event);
    }

    if (!isSelection || !isMultiSelect) {
      onChange(event);
      handleChange?.(event);

      return;
    }

    const isActive = value?.find((item: T) => item.id === event.id);

    const newValue = isActive
      ? value.filter((item: T) => item.id !== event.id)
      : [...(value ?? []), event];

    onChange(newValue);
    handleChange?.(newValue);
  };

  const showOptions = (event: ChangeEvent<HTMLElement> | MouseEvent<HTMLElement>) => {
    const { type } = event;

    if (!readOnly && ['change', 'focus'].includes(type)) {
      setIsOpen(shouldOpen);
    }

    if (readOnly && type === 'click') {
      setIsOpen(!isOpen);
    }
  };

  const handleActiveOption = (option?: T) => {
    if (option === activeOption) {
      return;
    }

    setActiveOption(option);
  };

  const handleSelectOption = (event: KeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    setIsOpen((prev) => !prev);

    if (noOptions) {
      return;
    }

    if (isOpen && activeOption) {
      onSelectWithAnalytics(activeOption);
    }
  };

  useEffect(() => {
    const isFocussed = document.activeElement === inputsRef.current;
    const [firstOption] = options;

    if (isFocussed && value && firstOption) {
      setIsOpen(true);
      handleActiveOption(firstOption);
    }
  }, [options]);

  const icon = (
    <ChevronIcon
      disabled={disabled}
      color="gray"
      className="pointer-events-none"
    />
  );

  return (
    <InputContainer
      className={cn(className, isOpen && 'z-10')}
      ref={ref}
      error={error}
      label={label}
      variant={variant}
      name={name}
      disabled={disabled}
    >
      <input
        value={inputValue ?? ''}
        ref={inputsRef}
        data-testid={name}
        id={name}
        name={name}
        readOnly={readOnly}
        type="text"
        autoComplete="off"
        placeholder={placeholder}
        disabled={disabled}
        className={cn(
          'w-full py-3 pl-4',
          autofill ? 'bg-blue-autocomplete' : 'bg-transparent',
          hasError || endAdornment ? 'pr-[54px]' : 'pr-10',
        )}
        onChange={(event: ChangeEvent<HTMLElement>) => {
          onSelectWithAnalytics(event);
          showOptions(event);
        }}
        onClick={(event: MouseEvent<HTMLElement>) => {
          showOptions(event);
        }}
        onBlur={() => {
          onBlur();
          setIsOpen(false);
        }}
        onFocus={(event: ChangeEvent<HTMLElement>) => {
          const optionView = optionRef.current;

          if (!optionView) {
            return;
          }

          showOptions(event);
          onFocus?.();

          const index = options.findIndex((option) => option.id === value?.id);

          const option = options[index];
          const [firstOption] = options;

          const indexes = [...Array(Math.max(0, index)).keys()];

          const result = indexes.reduce((acc, cur) => (
            acc + ((optionView.children[cur] as HTMLElement).offsetHeight)), 0,
          );

          const element = optionView.children[index] as HTMLElement;
          const offsetHeight = element?.offsetHeight ?? 0;

          const height = optionView.offsetHeight;
          const child = offsetHeight;

          optionView.scroll(0, result - height + child);
          handleActiveOption(option ?? firstOption);
        }}
        onKeyDown={async (event: KeyboardEvent<HTMLElement>) => {
          switch (event.code) {
            case 'ArrowDown':
            case 'ArrowUp': {
              event.preventDefault();
              setIsOpen(true);

              if (!isOpen) {
                return;
              }

              const indexMapper = { ArrowUp: -1, ArrowDown: 1 };
              const number = indexMapper[event.code];

              const index = options.findIndex((option) => option.id === activeOption?.id);
              const newIndex = index + number;

              const option = options[newIndex];
              const optionView = optionRef.current;

              if (!option || !optionView) {
                return;
              }

              const positionIndex = newIndex + (event.code === 'ArrowDown' ? 1 : 0);

              const height = optionView.offsetHeight;
              const offset = optionView.scrollTop;

              const viewPosition = height + offset;
              const indexes = [...Array(Math.max(0, positionIndex)).keys()];

              const result = indexes.reduce((acc, cur) => (
                acc + ((optionView.children[cur] as HTMLElement).offsetHeight)), 0,
              );

              if (result < offset) {
                optionView.scroll(0, result);
              }

              if (result > viewPosition) {
                optionView.scroll(0, result - height);
              }

              handleActiveOption(option);
              break;
            }
            case 'Tab':
              if (!isOpen) {
                break;
              }

              handleSelectOption(event);
              break;
            case 'Space':
              if (!isMultiSelect || !isOpen || !activeOption) {
                return;
              }

              event.preventDefault();
              onSelectWithAnalytics(activeOption);

              break;
            case 'Enter':
            case 'NumpadEnter':
              handleSelectOption(event);
              break;
            case 'Escape':
              event.preventDefault();
              setIsOpen(false);

              break;
            default: break;
          }
        }}
      />

      {!hasError && (
        <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
          {isLoading ? <Loader /> : endAdornment ?? icon}
        </div>
      )}

      {/* Dropdown options */}
      <AnimatePresence mode="wait">
        <motion.ul
          className={joinValues({
            base: 'absolute -left-px -right-px top-full overflow-auto rounded bg-white text-base shadow-lg z-20',
            spacing: 'mt-1 py-0',
            size: sizes[size],
            sm: 'sm:text-sm',
            focus: 'ring-1 ring-black ring-opacity-5 focus:outline-none',
            visible: !isOpen && 'pointer-events-none',
          })}
          data-testid={`option-view_${name}`}
          ref={optionRef}
          initial={{ opacity: 0 }}
          animate={{ opacity: isOpen ? 1 : 0 }}
          exit={{ opacity: 0 }}
          transition={transition}
          layout="position"
          onAnimationComplete={
            () => isMultiSelect && options.sort(sortBySelected)
          }
        >
          {options.map((option) => {
            const Icon = option.icon;
            const isActive = option.id === activeOption?.id;

            const isSelected = isMultiSelect
              ? value?.some((item: T) => item.id === option.id)
              : option.id === value?.id;

            const { inactive } = option;

            const textEnabled = isActive
              ? 'bg-secondary text-white'
              : 'text-off-black';

            const textDisabled = 'text-gray-400 bg-gray-100 cursor-default';
            const text = inactive ? textDisabled : textEnabled;

            const descriptions = typeof option.description === 'string'
              ? [option.description]
              : option.description;

            return (
              <li
                className="group cursor-default select-none relative"
                key={option.id}
              >
                <button
                  onMouseOver={() => handleActiveOption(option)}
                  onMouseDown={(event) => event.preventDefault()}
                  onFocus={() => { }}
                  onMouseUp={() => {
                    if (inactive) {
                      return;
                    }

                    onSelectWithAnalytics(option);

                    if (!isMultiSelect) {
                      inputsRef.current?.blur();
                    }
                  }}
                  aria-label="option-view_list_button"
                  type="button"
                  className={cn('flex flex-col w-full p-4 text-left', text)}
                  tabIndex={-1}
                  disabled={noOptions}
                  data-testid={option.id}
                >
                  <div className="flex justify-between items-center w-full">
                    <div className="flex gap-3">
                      {Icon && <Icon className="w-5 h-5 mt-0.5a" />}
                      <Text
                        weight={isSelected ? 'semibold' : 'normal'}
                        size="sm"
                        color="custom"
                      >
                        {displayValue(option)}
                      </Text>
                    </div>
                    {isSelected && (
                      <span className={isActive ? 'text-white' : 'text-secondary group-hover'}>
                        <CheckIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                      </span>
                    )}
                  </div>
                  {descriptions?.map((description) => (
                    <Text
                      className={cn(isActive ? 'text-indigo-200' : 'text-gray-500', 'mt-2')}
                      size="sm"
                      color="custom"
                      key={`${getValue(option)}-${description}`}
                    >
                      {description}
                    </Text>
                  ))}
                </button>
              </li>
            );
          })}
        </motion.ul>
      </AnimatePresence>
    </InputContainer>
  );
}

export default InputOptionView;
