import React, { type FC, type SVGAttributes } from 'react';
import { cn, joinValues, transition } from '../../utils/helpers';
import ComponentLayout from '../layout/ComponentLayout';
import Divider from '../ui/Divider';
import Header from '../ui/Header';
import AbnLarge from '../../public/icons/bank/abn_lg.svg';
import AbnSmall from '../../public/icons/bank/abn_sm.svg';
import AsnLarge from '../../public/icons/bank/asn_lg.svg';
import AsnSmall from '../../public/icons/bank/asn_sm.svg';
import IngLarge from '../../public/icons/bank/ing_lg.svg';
import IngSmall from '../../public/icons/bank/ing_sm.svg';
import KnabLarge from '../../public/icons/bank/knab_lg.svg';
import KnabSmall from '../../public/icons/bank/knab_sm.svg';
import RabobankLarge from '../../public/icons/bank/rabobank_lg.svg';
import RabobankSmall from '../../public/icons/bank/rabobank_sm.svg';
import RegiobankLarge from '../../public/icons/bank/regiobank_lg.svg';
import RegiobankSmall from '../../public/icons/bank/regiobank_sm.svg';
import SnsLarge from '../../public/icons/bank/sns_lg.svg';
import SnsSmall from '../../public/icons/bank/sns_sm.svg';

type IconSmallProps = {
  Icon: FC<SVGAttributes<SVGElement>>;
};

function IconSmall({ Icon }: IconSmallProps) {
  return (
    <Icon className="w-8" />
  );
}

type SupportedBanksProps = {
  className?: string;
  variant?: 'base' | 'sidebar';
};

function SupportedBanks({ className, variant = 'base' }: SupportedBanksProps) {
  const component = 'supported_banks';

  const base = (
    <ComponentLayout
      transition={transition}
      layoutId={component}
      className={joinValues({
        options: className,
        base: 'flex-col items-center gap-4 py-10 text-center',
        sm: 'sm:gap-6',
        lg: 'lg:py-20',
      })}
    >
      <Header
        langKey="supported_banks.title.long"
        size="h4"
        element="h2"
      />
      <div
        className={joinValues({
          base: 'hidden flex-wrap justify-evenly items-center gap-10 px-8',
          sm: 'sm:flex',
        })}
      >
        <RabobankLarge />
        <AbnLarge />
        <IngLarge />
        <RegiobankLarge />
        <AsnLarge />
        <SnsLarge />
        <KnabLarge />
      </div>

      <div className="flex flex-col gap-6 sm:hidden">
        <div className="flex flex-wrap gap-10 justify-evenly">
          <IconSmall Icon={RabobankSmall} />
          <IconSmall Icon={AbnSmall} />
          <IconSmall Icon={AsnSmall} />
          <IconSmall Icon={IngSmall} />
        </div>
        <div className="flex flex-wrap gap-10 justify-evenly">
          <IconSmall Icon={KnabSmall} />
          <IconSmall Icon={RegiobankSmall} />
          <IconSmall Icon={SnsSmall} />
        </div>
      </div>
    </ComponentLayout>
  );

  const sidebar = (
    <div className={cn(className, 'flex flex-col gap-4 bg-white rounded pt-5 pb-6')}>
      <Header
        langKey="text_65b81a9dff3404009b604a86"
        element="h4"
        size="h5"
        className="px-8"
      />

      <Divider className="mt-1" />

      <div className="flex flex-col gap-6 px-8">
        <div className="flex flex-wrap justify-start gap-8">
          <IconSmall Icon={RabobankSmall} />
          <IconSmall Icon={AbnSmall} />
          <IconSmall Icon={AsnSmall} />
          <IconSmall Icon={IngSmall} />
        </div>
        <div className="flex flex-wrap justify-start gap-8">
          <IconSmall Icon={KnabSmall} />
          <IconSmall Icon={RegiobankSmall} />
          <IconSmall Icon={SnsSmall} />
        </div>
      </div>
    </div>
  );

  return { base, sidebar }[variant];
}

export default SupportedBanks;
