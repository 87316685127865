const { requester1, requester2 } = require('../../e2e/fixtures/requester');

export default [
  {
    name: requester1.name,
    kvkNumber: requester1.kvkNumber,
    id: 'NL-X-123456789',
    street: 'Mock Street',
    homeNumber: '1',
    pilotBank: true,
    postalCode: '1234AB',
    city: 'Mock City',
    newToTheBank: false,
  },
  {
    name: requester2.name,
    kvkNumber: requester2.kvkNumber,
    id: 'NL-X-987654321',
    street: 'Mock Street',
    homeNumber: '1',
    pilotBank: true,
    postalCode: '1234AB',
    city: 'Mock City',
    newToTheBank: true,
  },
];
