import React from 'react';
import ReactDOM from 'react-dom/client';
import { withSplitFactory } from '@splitsoftware/splitio-react';
import sentryInit from '../sentry.ts';
import splitConfig from '../split.config.ts';
import App from './App.tsx';

const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
  sentryInit();
}

const Main = isProduction
  ? withSplitFactory(splitConfig)(App)
  : App;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Main />);
