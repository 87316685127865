import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LayoutGroup } from 'framer-motion';
import Lottie from 'lottie-react';
import { delay } from 'msw';
import { type ConnectionDetail, getBankAccounts } from '../../services/pbl-api';
import usePageMeasure from '../../hooks/usePageMeasure';
import AuthError from '../../utils/AuthError';
import { pageMapper, storageKeys } from '../../utils/constants';
import type { RejectionError } from '../../utils/errors';
import { loop } from '../../utils/helpers';
import { useStoreContext } from '../../utils/store';
import NeedHelp from '../../components/container/NeedHelp';
import ProgressSteps, { type Step } from '../../components/container/ProgressSteps';
import SupportedBanks from '../../components/container/SupportedBanks';
import PageLayout from '../../components/layout/PageLayout';
import Divider from '../../components/ui/Divider';
import Header from '../../components/ui/Header';
import PageTitle from '../../components/ui/PageTitle';
import Text from '../../components/ui/Text';
import CoinAnimation from '../../public/animations/loading_coin.json';

const page = 'get_consent';

function GetReconsent() {
  const [activeId, setActiveId] = useState(1);
  usePageMeasure('getReconsent');

  const navigate = useNavigate();
  const location = useLocation();

  const { returnPage, connectionId, bankName } = location.state;

  const { state } = useStoreContext();
  const { transactionsTab } = state;

  const steps: Step[] = [
    {
      id: 1,
      header: 'text_65b9af88774e154fb120cf61',
      text: {
        key: 'text_65b9af88774e154fb120cf62',
        value: { bankName },
      },
    },
    {
      id: 2,
      header: 'text_65b9af88774e154fb120cf64',
      text: {
        key: 'text_65b9af88774e154fb120cf65',
      },
    },
    {
      id: 3,
      header: 'text_65b9af88774e154fb120cf67',
      text: {
        key: 'text_65b9af88774e154fb120cf68',
      },
    },
  ];

  const onNoResults = () => {
    const error: RejectionError = 'TIMEOUT';
    navigate(pageMapper.requestFailed, { state: { error } });
  };

  const onError = (error: RejectionError) => {
    navigate(pageMapper.requestFailed, { state: { error } });
  };

  const handleResult = async () => {
    const caseId = sessionStorage.getItem(storageKeys.caseId);

    if (!caseId) {
      throw new AuthError('caseIdNotFound');
    }

    try {
      const { data } = await getBankAccounts({ caseId, inScopeForReconsent: true });
      const { connectionDetails } = data;

      const connection = connectionDetails
        .find((item: ConnectionDetail) => item.connectionId === connectionId);

      if (!connection || connection.needReconsent) {
        return false;
      }

      transactionsTab?.close();
      setActiveId((prev) => prev + 1);

      await delay(2000);
      navigate(returnPage);
    } catch (err) {
      onError('TRANSACTION_STATUS');
    }

    return true;
  };

  useEffect(() => {
    setActiveId((prev) => prev + 1);

    const interval = loop({
      limit: 240,
      delay: 2000,
      fn: handleResult,
      callback: onNoResults,
    });

    return () => clearInterval(interval);
  }, []);

  return (
    <LayoutGroup id={page}>
      <PageTitle
        description="text_652e8d045b766300555ca649"
        title="text_652e8d045b766300555ca647"
      />

      <PageLayout>
        <PageLayout.MainColumn className="flex-col items-center py-8 gap-5 h-full">
          <div className="flex flex-col max-w-md gap-5">
            <Lottie
              animationData={CoinAnimation}
              loop
              style={{ height: 300 }}
              className="max-h-32 lg:max-h-48"
            />

            <div className="flex flex-col gap-5">
              <Header
                langKey="text_65b9af88774e154fb120cf5f"
                element="h1"
                size="h3"
                className="text-center"
                values={{ bankName }}
              />
              <Text
                langKey="text_65b9af88774e154fb120cf60"
                size="sm"
                className="text-center"
              />
            </div>

            <Divider className="w-full" />

            <ProgressSteps
              activeId={activeId}
              showLoader
              steps={steps}
            />
          </div>
        </PageLayout.MainColumn>

        <PageLayout.SideColumn>
          <SupportedBanks
            className="hidden lg:flex"
            variant="sidebar"
          />
          <NeedHelp />
        </PageLayout.SideColumn>
      </PageLayout>
    </LayoutGroup>
  );
}

export default GetReconsent;
