import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import useInterceptor from '../hooks/useInterceptor';
import usePrevious from '../hooks/usePrevious';
import { pageView, updateAnalyticSettings } from '../utils/analytics';
import { pageMapper, storageKeys, utmSources } from '../utils/constants.ts';
import { getCookieValue, getQueryParams, queryKeys, setCookie } from '../utils/helpers.ts';
import { useStoreContext } from '../utils/store.tsx';
import CookieConsent from '../components/container/CookieConsent.tsx';
import SessionExpired from '../components/container/SessionExpired.tsx';
import Modal from '../components/ui/Modal.tsx';
import worker from '../mocks/browser';

const skipLocationSave = [
  pageMapper.getTransactions,
  pageMapper.getOffers,
  pageMapper.getReconsent,
];

function AppSetup({ children }) {
  const [setupFinished, setSetupFinished] = useState(false);

  const queryParams = getQueryParams();
  useAuth(setupFinished);

  const location = useLocation();
  const { pathname } = location;

  const hasCookieConsent = getCookieValue('cookie_consent') === 'true';
  const cookieTester = getCookieValue('tester') === 'true';

  const shouldSkip = skipLocationSave.includes(pathname);
  const previousLocation = usePrevious(pathname, shouldSkip);

  const { actions } = useStoreContext();
  const { setIsTester, setLocation } = actions;

  const [showCookieModal, setShowCookieModal] = useState();
  const [tokenExpired, setTokenExpired] = useInterceptor();

  const onLocationChange = () => {
    pageView(window.location.href);

    setLocation({
      prev: previousLocation,
      curr: pathname,
    });
  };

  const handleTester = async (isTester) => {
    const isProduction = process.env.NODE_ENV === 'production';

    if (isProduction) {
      return;
    }

    if (isTester) {
      await worker.start();
    }

    updateAnalyticSettings(isTester);
    setIsTester(isTester);
  };

  const setupTester = async () => {
    const urlHasTester = queryParams[queryKeys.tester] === 'true';

    const isTester = (cookieTester || urlHasTester);
    await handleTester(isTester);

    const expireDate = new Date();
    expireDate.setHours(expireDate.getHours() + 24);

    setCookie({ name: 'tester', value: isTester, expires: expireDate });
  };

  const setupPartner = () => {
    const isPartner = queryParams[queryKeys.utmSource] === utmSources.partner;

    if (isPartner) {
      sessionStorage.setItem(storageKeys.isPartner, true);
    }
  };

  useEffect(() => {
    const initialise = async () => {
      await setupTester();
      setupPartner();

      setSetupFinished(true);
    };

    initialise();
  }, []);

  useEffect(() => onLocationChange(), [pathname]);

  useEffect(() => {
    setShowCookieModal(!hasCookieConsent && pathname !== pageMapper.cookies);
  }, [hasCookieConsent, pathname]);

  return (
    <>
      {setupFinished && children}

      <CookieConsent
        setIsOpen={setShowCookieModal}
        isOpen={showCookieModal}
      />

      <Modal
        setIsOpen={setTokenExpired}
        isOpen={tokenExpired}
        className="max-w-md lg:max-w-xl"
      >
        <SessionExpired setIsOpen={setTokenExpired} />
      </Modal>
    </>
  );
}

export default AppSetup;
