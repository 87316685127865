import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LayoutGroup, motion } from 'framer-motion';
import { type BankCode, bankCodeToBankName, reconnect } from '../../services/pbl-api';
import useBankAccounts from '../../hooks/useBankAccounts';
import usePageMeasure from '../../hooks/usePageMeasure';
import { pageMapper } from '../../utils/constants';
import { getStatusError, transition, transitionDuration } from '../../utils/helpers';
import { useStoreContext } from '../../utils/store';
import NeedHelp from '../../components/container/NeedHelp';
import Questions from '../../components/container/Questions';
import SupportedBanks from '../../components/container/SupportedBanks';
import PageLayout from '../../components/layout/PageLayout';
import ErrorMessage from '../../components/ui/ErrorMessage';
import Header from '../../components/ui/Header';
import Loader from '../../components/ui/Loader';
import Modal from '../../components/ui/Modal';
import Text from '../../components/ui/Text';
import BankCard from './BankCard';
import ReconsentSuccess from './ReconsentSuccess';

const page = 'reconsent_overview';

const base = process.env.REACT_APP_BASE_URL;

let isInitial = true;

const faqItems = [
  {
    header: 'faq.item7.question',
    text: 'faq.item7.answer',
  },
  {
    header: 'faq.item12.question',
    text: 'faq.item12.answer',
  },
  {
    header: 'faq.item8.question',
    text: 'faq.item8.answer',
  },
  {
    header: 'faq.item6.question',
    text: 'faq.item6.answer',
  },
];

type ReconnectError = {
  bank: BankCode;
  errorKey: string;
};

function Overview() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const navigate = useNavigate();
  const [reconnectError, setReconnectError] = useState<ReconnectError>();

  const { actions } = useStoreContext();
  const { setTransactionsTab } = actions;

  const { error, isFinished, connections } = useBankAccounts();
  const needReconsent = connections.some((connection) => connection.needReconsent);

  usePageMeasure('reconsentOverview');
  const showIsFinished = isFinished && !needReconsent && !error && !isInitial;

  const onExtendConsent = async (connectionId: string, bank: BankCode) => {
    const bankName = bankCodeToBankName[bank];
    const returnUrl = `${window.location.origin}${pageMapper.transactionSuccess}`;

    const body = {
      returnUrl,
      connectionId,
    };

    setReconnectError(undefined);
    setIsLoading(true);

    const tab = window.open(`${base}${pageMapper.redirection}`);

    if (!tab) {
      return;
    }

    try {
      const { data } = await reconnect(body);
      const { connectUrl } = data;

      tab.location = connectUrl;
      const returnPage = pageMapper.reconsent;

      const props = {
        returnPage,
        bankName,
        connectionId,
      };

      setTransactionsTab(tab);
      isInitial = false;

      navigate(pageMapper.getReconsent, { state: props });
    } catch (err) {
      setIsLoading(false);
      tab.close();

      const errorKey = getStatusError(err);
      setReconnectError({ bank, errorKey });
    }
  };

  useEffect(() => {
    if (!showIsFinished) {
      return;
    }

    setIsSuccess(true);
  }, [showIsFinished]);

  const transactions = (
    connections?.map((connection) => {
      const {
        connectionId,
        accounts,
        bankName,
        consentExpiresAt,
      } = connection;

      const { bank, errorKey } = reconnectError ?? {};
      const hasError = bank === bankName;

      if (!consentExpiresAt) {
        return null;
      }

      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            ...transition,
            delay: transitionDuration,
          }}
          className="flex flex-col gap-4 lg:gap-8"
          key={connectionId}
        >
          <BankCard
            needReconsent={connection.needReconsent ?? false}
            isLoading={isLoading}
            accounts={accounts}
            bankName={bankName}
            errorKey={hasError ? errorKey : undefined}
            expireAt={consentExpiresAt}
            connectionId={connectionId}
            onClick={() => onExtendConsent(connectionId, bankName)}
          />
        </motion.div>
      );
    })
  );

  return (
    <LayoutGroup id={page}>
      <PageLayout>
        <PageLayout.MainColumn>
          <motion.div
            transition={transition}
            layoutId="container"
            className="flex flex-col gap-4 pb-8 lg:gap-8 lg:pb-0"
          >
            <motion.div layout="position">
              <div className="flex flex-col gap-4 lg:gap-2">
                <Header
                  langKey="text_65b9af88774e154fb120cf77"
                  element="h1"
                />
                <Text
                  langKey="text_65b9af88774e154fb120cf78"
                />
              </div>
            </motion.div>

            <motion.div
              layoutId="account_header"
              layout="position"
              transition={transition}
            >
              <Header
                langKey="text_65b9af88774e154fb120cf79"
                element="h4"
              />
            </motion.div>

            {!isFinished && <Loader />}

            {error
              ? <ErrorMessage name={`${page}_error`} errorKey={error} />
              : transactions}

          </motion.div>
        </PageLayout.MainColumn>

        <PageLayout.SideColumn>
          <SupportedBanks
            className="hidden lg:flex"
            variant="sidebar"
          />
          <Questions
            header="text_65b9af88774e154fb120cf94"
            items={faqItems}
            bottomOffset={0}
          />
          <NeedHelp />
        </PageLayout.SideColumn>
      </PageLayout>

      <Modal
        className="max-w-2xl"
        position="bottom"
        isOpen={isSuccess}
        setIsOpen={setIsSuccess}
      >
        <ReconsentSuccess setIsOpen={setIsSuccess} />
      </Modal>
    </LayoutGroup>
  );
}
export default Overview;
