import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';

export const config = {
  lng: 'base',
  fallbackLng: 'base',
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false,
  },
  ns: ['.dev', 'default', 'empty'],
  fallbackNS: ['default', 'empty'],
  defaultNS: '.dev',
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'u', 'span'],
    useSuspense: true,
  },
};

const backend = async (lng, ns, callback) => {
  const namespace = ns === 'empty' ? '' : ns;

  try {
    const resources = ns === 'default'
      ? await import(`./public/locales/ditto__${lng}.json`)
      : await import(`./public/locales/ditto-component-library__${lng}${namespace}.json`);

    callback(null, resources);
  } catch (error) {
    callback(error, null);
  }
};

i18n
  .use(resourcesToBackend(backend))
  .use(initReactI18next)
  .use(I18nextBrowserLanguageDetector)
  .init(config);

export default i18n;
